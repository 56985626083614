<template>
  <div>
    <el-dialog
        :title="'Chi tiết gói giao diện'"
        :visible.sync="isOpenDetailTemplateAppDialog"
        width="1000px"
        :before-close="closeDialog"
        :close-on-click-modal="false"
        top="3vh"
        border
    >
      <el-form
          label-width="140px"
          :model="detailTemplateData"
          label-position="left"
          :rules="rules"
          ref="detailTemplateData"
      >
        <el-row :gutter="50">
          <el-col :span="12">
            <div class="grid-content">
              <el-form-item label="Tên template" prop="nameTemplate">
                <el-input
                    readonly
                    v-model="detailTemplateData.nameTemplate"
                    placeholder="Nhập tên template"
                ></el-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content">
              <el-form-item label="Mô tả" prop="descriptionTemplate">
                <el-input
                    readonly
                    v-model="detailTemplateData.descriptionTemplate"
                    placeholder="Nhập mô tả"
                ></el-input>
              </el-form-item>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="50">
          <el-col :span="12">
            <div class="grid-content">
              <el-form-item label="Loại App" prop="appType">
                <h4 class="app_name">
                  {{ detailTemplateData.appType }}
                </h4>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content">
              <el-form-item label="Tên trường" prop="nameSchool">
                <h4 class="school_name">
                  {{ detailTemplateData.nameSchool }}
                </h4>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12" v-if="detailTemplateData.appType === 'plus'">
            <el-form-item>
              <el-radio-group v-model="typePackage">
                <el-radio label="template">Giao diện</el-radio>
                <el-radio label="ticket" style="margin-left: 28px">Phiếu bé ngoan</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>

        <el-table
            v-if="typePackage === 'template'"
            ref="multipleTable"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255,255,255, 0)"
            highlight-current-row
            :data="screenList"
            :header-cell-style="tableHeaderColor"
            :max-height="$tableMaxHeight"
            border
        >
          <el-table-column
              type="index"
              label="STT"
              width="80"
              align="center"
          ></el-table-column>
          <el-table-column
              label="Màn hình"
              min-width="150"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.value }}</span>
            </template>
          </el-table-column>

          <el-table-column label="Ảnh" width="150px" align="center">
            <template slot-scope="scope">
              <div>
                <el-image
                    @click="showImageTemplate(scope.row)"
                    :src="scope.row.url"
                    style="width: 50px; height: 50px;"
                    class="el-image-opacity"
                    :fit="contain"
                >
                </el-image>
                <el-dialog width="30%" :visible.sync="scope.row.dialogImg">
                  <img width="100%" :src="scope.row.url" alt/>
                </el-dialog>
              </div>

            </template>
          </el-table-column>


          <el-table-column
              label="Link"
              min-width="150"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.url }}</span>
            </template>
          </el-table-column>
        </el-table>

        <el-row style="
         display: flex;
         flex-wrap: wrap;
         width: 100%;
         margin-top: 10px;
         border: 1px solid rgb(96 99 102 / 19%);
         overflow-y: scroll; max-height: 600px;
         padding-left: 50px;" v-if="typePackage === 'ticket'">
          <div v-for="item in listTicket" :key="item.id"
               style="flex: 0 0 223px; margin: 30px; width: 223px">
            <div class="inline-block-div">
              <el-image
                  @click="showListImageTicket(item)"
                  :src="item.url"
                  style="width: 250px; height: 200px"
              ></el-image>
            </div>
            <div v-if="item.numberPicture !== 0">
              <h4 style="color: #30313499; text-align: left; margin-left: 5px;  overflow: hidden; max-height: 20px;
                  text-overflow: ellipsis; white-space: nowrap;">{{ item.name }}</h4>
              <span style="margin-right: 5px; float: left" class="demonstration">
                {{ item.numberPicture }}
                <i class="el-icon-picture" style="color: #6298d0"></i>
              </span>
            </div>
          </div>
        </el-row>
        <div class="tab-infor-button">
          <el-button
              type="danger"
              size="medium"
              @click="closeDialogByButton('detailTemplateData')"
          >
            <i class="el-icon-circle-close"/>
            <span>Đóng</span>
          </el-button>
        </div>
      </el-form>
    </el-dialog>
    <div>
      <DetailTicketDialog
          :showDetailTicket="showDetailTicket"
          :titleTicket="titleTicket"
          :listTicketDetail="listTicketDetail"
          @close="handelCloseDetailTicketDialog"
      />
    </div>
  </div>

</template>

<script>
import SystemConfigService from "@/services/AdminService/SystemConfigService";
import {listTicketRequest, parentScreenList, plusScreenList, teacherScreenList} from "@/constant/listTemplate";
import templateConfigMixin from "@/global/mixinTemplate/templateConfig.mixin";
import DetailTicketDialog from "@/views/master/school/templateApp/dialog/DetailTicketDialog.vue";

export default {
  components: {DetailTicketDialog},
  props: {
    isOpenDetailTemplateAppDialog: {
      type: Boolean,
      default: () => false,
    },
  },

  data() {
    return {

      detailTemplateData: {
        idSchool: "",
        nameSchool: "",
        nameTemplate: "",
        descriptionTemplate: "",
        appType: "plus",
        pictureList: [],
        listMap: {},
      },
      rules: {

      },
      plusScreenList,
      teacherScreenList,
      parentScreenList,
      typePackage: "template",
      listTicketLearn: [],
      listTicketEat: [],
      listTicketSleep: [],
      listTicketToilet: [],
      listTicketHealthy: [],
      listTicketComment: [],
      listTicketWeek: [],
      listTicketMonth: [],
      listTicketPeriodic: [],
      listTicket: [],
      listTicketRequest,
      showDetailTicket: false,
      titleTicket: '',
      listTicketDetail: []
    };
  },
  mixins: [
    templateConfigMixin
  ],
  computed: {
    screenList() {
      if (this.detailTemplateData.appType === "plus") {
        return this.plusScreenList
      } else if (this.detailTemplateData.appType === "teacher") {
        return this.teacherScreenList
      } else {
        return this.parentScreenList
      }
    }
  },
  methods: {
    resetFormData() {
      this.$refs["detailTemplateData"].resetFields();
    },
    tableHeaderColor() {
      return "background-color: #78a5e7;color: #fff;font-weight: bold;";
    },

    closeDialog() {
      this.$refs["detailTemplateData"].resetFields();
      this.handleClearList()
      this.$emit("dialog-close");
    },
    closeDialogByButton(fromData) {
      this.$refs[fromData].resetFields();
      this.handleClearList()
      this.$emit("dialog-close");
    },
    handleClearList() {
      this.listTicketLearn = []
      this.listTicketEat = []
      this.listTicketSleep = []
      this.listTicketToilet = []
      this.listTicketHealthy = []
      this.listTicketComment = []
      this.listTicketWeek = []
      this.listTicketMonth = []
      this.listTicketPeriodic = []
      this.typePackage = "template"
      for (let i = 0; i < this.plusScreenList.length; i++) {
        this.plusScreenList[i].file = null;
        this.plusScreenList[i].url = '';
        this.plusScreenList[i].showFile = true;
        this.plusScreenList[i].id = null
        this.plusScreenList[i].showFile = true
        this.plusScreenList[i].disabled = false
        this.plusScreenList[i].urlKeep = ''
      }
      for (let i = 0; i < this.teacherScreenList.length; i++) {
        this.teacherScreenList[i].file = null;
        this.teacherScreenList[i].url = "";
        this.teacherScreenList[i].showFile = true;
        this.teacherScreenList[i].id = null
        this.teacherScreenList[i].showFile = true
        this.teacherScreenList[i].disabled = false
        this.teacherScreenList[i].urlKeep = ''
      }
      for (let i = 0; i < this.parentScreenList.length; i++) {
        this.parentScreenList[i].file = null;
        this.parentScreenList[i].url = '';
        this.parentScreenList[i].showFile = true;
        this.teacherScreenList[i].id = null
        this.teacherScreenList[i].showFile = true
        this.teacherScreenList[i].disabled = false
        this.teacherScreenList[i].urlKeep = ''
      }
    },
    async getDataInitialDetailTemplateAppDialog(nameSchool, row) {
      let idTemplate = row.id
      this.detailTemplateData.descriptionTemplate = row.descriptionTemplate;
      this.detailTemplateData.nameTemplate = row.nameTemplate;
      this.detailTemplateData.nameSchool = nameSchool;
      this.detailTemplateData.appType = row.appType;
      let dataList = [];
      await SystemConfigService.getDetailTemplate(idTemplate)
          .then((resp) => {
            dataList = resp.data.data.listTemplate;
            this.listTicketLearn = resp.data.data.listTicketLearn
            this.listTicketEat = resp.data.data.listTicketEat
            this.listTicketSleep = resp.data.data.listTicketSleep
            this.listTicketToilet = resp.data.data.listTicketToilet
            this.listTicketHealthy = resp.data.data.listTicketHealthy
            this.listTicketComment = resp.data.data.listTicketComment
            this.listTicketWeek = resp.data.data.listTicketWeek
            this.listTicketMonth = resp.data.data.listTicketMonth
            this.listTicketPeriodic = resp.data.data.listTicketPeriodic
            if (this.detailTemplateData.appType === "plus") {
              for (let i = 0; i < this.plusScreenList.length; i++) {
                let plusTemplate = this.plusScreenList[i];
                let key = this.plusScreenList[i].key;
                for (let j = 0; j < dataList.length; j++) {
                  let data = dataList[j];
                  if (data.namePicture === key) {
                    plusTemplate.url = data.urlPicture
                  }
                }
              }

            } else if (this.detailTemplateData.appType === "teacher") {
              for (let i = 0; i < this.teacherScreenList.length; i++) {
                let teacherTemplate = this.teacherScreenList[i];
                let key = this.teacherScreenList[i].key;
                for (let j = 0; j < dataList.length; j++) {
                  let data = dataList[j];
                  if (data.namePicture === key) {
                    teacherTemplate.url = data.urlPicture
                  }
                }
              }
            } else {
              for (let i = 0; i < this.parentScreenList.length; i++) {
                let parentTemplate = this.parentScreenList[i];
                let key = this.parentScreenList[i].key;
                for (let j = 0; j < dataList.length; j++) {
                  let data = dataList[j];
                  if (data.namePicture === key) {
                    parentTemplate.url = data.urlPicture
                  }
                }
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      console.log("check data: ", this.listTicketLearn)
      this.listTicket = []
      this.addTicket(this.listTicketRequest, this.listTicketLearn, this.listTicketEat, this.listTicketSleep, this.listTicketToilet,
          this.listTicketHealthy, this.listTicketComment, this.listTicketWeek, this.listTicketMonth, this.listTicketPeriodic)
      for (let i = 0; i < this.listTicketRequest.length; i++) {
        if (this.listTicketRequest[i].numberPicture !== 0) {
          this.listTicket.push(this.listTicketRequest[i])
        }
      }

      console.log("check list: ", this.listTicket)

    },
    showImageTemplate(row) {
      if (this.detailTemplateData.appType === "plus") {
        for (let i = 0; i < this.plusScreenList.length; i++) {
          if (this.plusScreenList[i].key === row.key) {
            this.plusScreenList[i].url = row.url;
            this.plusScreenList[i].dialogImg = true;
          }
        }
      } else if (this.detailTemplateData.appType === "teacher") {
        for (let i = 0; i < this.teacherScreenList.length; i++) {
          if (this.teacherScreenList[i].key === row.key) {
            this.teacherScreenList[i].url = row.url;
            this.teacherScreenList[i].dialogImg = true;
          }
        }
      } else {
        for (let i = 0; i < this.detailTemplateData.length; i++) {
          if (this.parentScreenList[i].key === row.key) {
            this.parentScreenList[i].url = row.url;
            this.parentScreenList[i].dialogImg = true;
          }
        }
      }
    },

    showListImageTicket(item) {
      this.listTicketDetail = this.showListImageTicketPlus(item.id, this.listTicketLearn, this.listTicketEat, this.listTicketSleep, this.listTicketToilet,
          this.listTicketHealthy, this.listTicketComment, this.listTicketWeek, this.listTicketMonth, this.listTicketPeriodic)
      this.titleTicket = this.getNameTicket(item.id)
      this.showDetailTicket = true
    },
    handelCloseDetailTicketDialog() {
      this.showDetailTicket = false
    },

  },
};
</script>
<style>
.hideUpload > div {
  visibility: hidden;
}
</style>
<style lang="scss" scoped>

/deep/ .el-dialog__title {
  font-size: 25px;
  color: #606266;
}

/deep/ .el-form-item__label {
  font-family: Arial, Helvetica, sans-serif;
}

/deep/ .el-button--primary {
  width: 100%;
  background: #409eff;
}

/deep/ .upload-demo {
  text-align: center;

  .el-upload {
    width: 100%;
  }
}

/deep/ .el-avatar {
  display: inline;

  img {
    border-radius: 5px;
    width: 100%;
  }
}

/deep/ .el-input.is-disabled .el-input__inner {
  text-align: center;
  color: #d13b93;
  font-weight: bold;
}

/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.el-select {
  display: block;
}

.tab-infor-button {
  text-align: right;
  margin-top: 20px;
}

.bg-purple {
  background: #d3dce6;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.group-radio-status {
  margin: 0 50px;

  .kids-status {
    margin-bottom: 10px;
  }
}

/deep/ .el-tabs__nav {
  background: #a0a19ce0;
}

/deep/ .el-tabs__item {
  color: white;
}

/deep/ .el-tabs__item.is-active {
  color: white;
  background: #67c23a;
}

/deep/ .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:after {
  content: "*";
  color: #f56c6c;
  margin-left: 3px;
}

/deep/ .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  content: "";
  color: #f56c6c;
  margin-left: -4px;
}

/deep/ .el-image__error {
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #ffffff;
  vertical-align: middle;
}

.app_name {
  display: inline-block;
  margin: 0;
  text-transform: capitalize;
  font-size: 15px;
  padding: 0 12px;
  cursor: not-allowed;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.school_name {
  margin: 0;
  color: #3a8ee6;
  font-weight: 900;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/deep/.el-image__inner {
  object-fit: cover;
}
</style>
