<template>
  <div>
    <el-dialog
        :visible.sync="isOpenUpdateTemplateAppDialog"
        width="90%"
        :before-close="closeDialog"
        :close-on-click-modal="false"
        top="3vh"
        title="Cập nhật gói giao diện App"
        border
    >
      <el-form
          label-width="140px"
          :model="updateTemplateAppRequest"
          label-position="left"
          :rules="rules"
          ref="updateTemplateAppRequest"
      >
        <el-row :gutter="50">
          <el-col :span="10">
            <div class="grid-content">
              <el-form-item label="Tên template" prop="nameTemplate">
                <el-input
                    v-model="updateTemplateAppRequest.nameTemplate"
                    placeholder="Nhập tên template"
                ></el-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="14">
            <div class="grid-content">
              <el-form-item label="Mô tả" prop="descriptionTemplate">
                <el-input
                    v-model="updateTemplateAppRequest.descriptionTemplate"
                    placeholder="Nhập mô tả"
                ></el-input>
              </el-form-item>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="10">
            <el-form-item label="Phiên bản" prop="version" required>
              <span>{{ updateTemplateAppRequest.version }}</span>
              <el-input-number v-model="version" style="margin-left: 10px" :min="0"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <div class="grid-content">
              <el-form-item label="Loại App" style="margin-left: 20px;">
                <h4 class="app_name">
                  {{ updateTemplateAppRequest.appType }}
                </h4>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 10px">
          <el-col :span="8" :offset="13">
            <el-radio-group v-model="typePackage" @change="handleChangeTypeTicket">
              <el-radio label="template">Giao diện</el-radio>
              <el-radio label="ticket" style="margin-left: 50px" v-if="updateTemplateAppRequest.appType === 'plus'">
                Phiếu bé ngoan
              </el-radio>
            </el-radio-group>
          </el-col>
        </el-row>
        <el-row v-if="typePackage === 'ticket' && updateTemplateAppRequest.appType === 'plus'">
          <el-col :span="9">
            <el-form-item label="Màn hình">
              <el-select v-model="displayTicket" placeholder="Chọn màn hình" @change="handleChangeDisplayTicket"
                         style="width: 228px">
                <el-option
                    style="width: 200px"
                    v-for="item in listDisplayTicket"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <!--        Bảng chọn ảnh -->

        <!--   APP PLUS   -->
        <el-table
            v-if="updateTemplateAppRequest.appType === 'plus'  &&typePackage === 'template'"
            ref="multipleTable"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255,255,255, 0)"
            highlight-current-row
            :data="plusScreenList"
            :header-cell-style="tableHeaderColor"
            :max-height="500"
            border
        >
          <el-table-column
              type="index"
              label="STT"
              width="80"
              align="center"
          ></el-table-column>
          <el-table-column
              label="Màn hình"
              align="center"
              width="200px"
          >
            <template v-slot:default="scope">
              <span>{{ scope.row.value }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Ảnh hiện tại" align="center">
            <template v-slot:default="scope">
              <div>
                <el-image
                    :src="scope.row.urlKeep"
                    style="width: 150px; height: 150px;"
                    class="el-image-opacity"
                    @click="showTemplateDefault(scope.row.urlKeep)"
                ></el-image>
              </div>

            </template>
          </el-table-column>
          <el-table-column
              label="Chọn ảnh mới"
          >
            <template v-slot:default="scope">
              <el-upload
                  action="/api/upload/"
                  list-type="picture-card"
                  :auto-upload="false"
                  :file-list="getFileList(scope.row.file)"
                  :on-change="(file) => handleChangePicture(scope.row, file)"
                  :on-remove="(file) => handleRemove(scope.row, file)"
                  :before-remove="(file) => beforeRemove(scope.row, file)"
                  ref="upload"
                  accept="image/jpeg,image/png"
                  :disabled="scope.row.showFile === false"
              >
                <div v-if="scope.row.showFile === true">
                  <i slot="default" class="el-icon-plus" style="font-style: normal"
                  >Thêm ảnh</i
                  >
                </div>
                <div slot="file" slot-scope="{ file }">
                  <span class="tag_image">New</span>
                  <img class="el-upload-list__item-thumbnail" :src="scope.row.url" alt/>
                  <span class="el-upload-list__item-actions">
                    <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(scope.row, file)"
                    >
                        <i class="el-icon-zoom-in"></i>
                    </span>
                    <span
                        @click="handleRemove(scope.row, file)"
                        v-if="!scope.row.disabled"
                        class="el-upload-list__item-delete"
                    >
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
              <el-dialog width="30%" :visible.sync="scope.row.dialogImg">
                <img width="100%" :src="scope.row.url" alt/>
              </el-dialog>
            </template>
          </el-table-column>
          <el-table-column label="Tác vụ" width="100px" align="center">
            <template v-slot:default="scope">
              <el-button
                  :disabled="scope.row.id === null  || scope.row.urlKeep === ''"
                  size="mini"
                  type="danger"
                  @click="deleteTemplateSystem (scope.row.id)"
              >Xóa
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <!--   APP TEACHER   -->
        <el-table
            v-if="updateTemplateAppRequest.appType === 'teacher'&&typePackage === 'template'"
            ref="multipleTable"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255,255,255, 0)"
            highlight-current-row
            :data="teacherScreenList"
            :header-cell-style="tableHeaderColor"
            :max-height="500"
            border
        >
          <el-table-column
              type="index"
              label="STT"
              width="80"
              align="center"
          ></el-table-column>
          <el-table-column
              label="Màn hình"
              align="center"
              width="200px"
          >
            <template v-slot:default="scope">
              <span>{{ scope.row.value }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Ảnh hiện tại" align="center">
            <template v-slot:default="scope">
              <div>
                <el-image
                    :src="scope.row.urlKeep"
                    style="width: 150px; height: 150px;"
                    class="el-image-opacity"
                    @click="showTemplateDefault(scope.row.urlKeep)"
                ></el-image>
              </div>

            </template>
          </el-table-column>
          <el-table-column
              label="Chọn ảnh mới"
          >
            <template v-slot:default="scope">
              <el-upload
                  action="/api/upload/"
                  list-type="picture-card"
                  :auto-upload="false"
                  :file-list="getFileList(scope.row.file)"
                  :on-change="(file) => handleChangePicture(scope.row, file)"
                  :on-remove="(file) => handleRemove(scope.row, file)"
                  :before-remove="(file) => beforeRemove(scope.row, file)"
                  ref="upload"
                  accept="image/jpeg,image/png"
                  :disabled="scope.row.showFile === false"
              >
                <div v-if="scope.row.showFile === true">
                  <i slot="default" class="el-icon-plus" style="font-style: normal">Thêm ảnh</i>
                </div>
                <div slot="file" slot-scope="{ file }">
                  <span class="tag_image">New</span>
                  <img class="el-upload-list__item-thumbnail" :src="scope.row.url" alt/>
                  <span class="el-upload-list__item-actions">
                    <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(scope.row, file)"
                    >
                        <i class="el-icon-zoom-in"></i>
                    </span>
                    <span
                        @click="handleRemove(scope.row, file)"
                        v-if="!scope.row.disabled"
                        class="el-upload-list__item-delete"
                    >
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
              <el-dialog width="30%" :visible.sync="scope.row.dialogImg">
                <img width="100%" :src="scope.row.url" alt/>
              </el-dialog>
            </template>
          </el-table-column>
          <el-table-column label="Tác vụ" width="100px" align="center">
            <template v-slot:default="scope">
              <el-button
                  :disabled="scope.row.id === null  || scope.row.urlKeep === ''"
                  size="mini"
                  type="danger"
                  @click="deleteTemplateSystem (scope.row.id)"
              >Xóa
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <!--    APP PARENT    -->
        <el-table
            v-if="updateTemplateAppRequest.appType === 'parent'&&typePackage === 'template'"
            ref="multipleTable"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255,255,255, 0)"
            highlight-current-row
            :data="parentScreenList"
            :header-cell-style="tableHeaderColor"
            :max-height="500"
            border
        >
          <el-table-column
              type="index"
              label="STT"
              width="80"
              align="center"
          ></el-table-column>
          <el-table-column
              label="Màn hình"
              align="center"
              width="200px"
          >
            <template v-slot:default="scope">
              <span>{{ scope.row.value }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Ảnh hiện tại" align="center">
            <template v-slot:default="scope">
              <div>
                <el-image
                    :src="scope.row.urlKeep"
                    style="width: 150px; height: 150px;"
                    class="el-image-opacity"
                    @click="showTemplateDefault(scope.row.urlKeep)"
                ></el-image>
              </div>

            </template>
          </el-table-column>
          <el-table-column
              label="Chọn ảnh mới"
          >
            <template v-slot:default="scope">
              <el-upload
                  action="/api/upload/"
                  list-type="picture-card"
                  :auto-upload="false"
                  :file-list="getFileList(scope.row.file)"
                  :on-change="(file) => handleChangePicture(scope.row, file)"
                  :on-remove="(file) => handleRemove(scope.row, file)"
                  :before-remove="(file) => beforeRemove(scope.row, file)"
                  ref="upload"
                  accept="image/jpeg,image/png"
                  :disabled="scope.row.showFile === false"
              >
                <div v-if="scope.row.showFile === true">
                  <i slot="default" class="el-icon-plus" style="font-style: normal">Thêm ảnh</i>
                </div>
                <div slot="file" slot-scope="{ file }">
                  <span class="tag_image">New</span>
                  <img class="el-upload-list__item-thumbnail" :src="scope.row.url" alt/>
                  <span class="el-upload-list__item-actions">
                    <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(scope.row, file)"
                    >
                        <i class="el-icon-zoom-in"></i>
                    </span>
                    <span
                        @click="handleRemove(scope.row, file)"
                        v-if="!scope.row.disabled"
                        class="el-upload-list__item-delete"
                    >
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
              <el-dialog width="30%" :visible.sync="scope.row.dialogImg">
                <img width="100%" :src="scope.row.url" alt/>
              </el-dialog>
            </template>
          </el-table-column>
          <el-table-column label="Tác vụ" width="100px" align="center">
            <template v-slot:default="scope">
              <el-button
                  :disabled="scope.row.id === null  || scope.row.urlKeep === ''"
                  size="mini"
                  type="danger"
                  @click="deleteTemplateSystem (scope.row.id)"
              >Xóa
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <!--        Phiếu bé ngoan-->
        <div v-if="typePackage === 'ticket' && updateTemplateAppRequest.appType === 'plus'">
          <el-row>
            <span><b style="font-size: 17px">Thêm ảnh</b></span>
          </el-row>
          <hr/>
          <template v-if="typePackage === 'ticket'" style="margin-left: 100px">
            <el-upload
                style="margin-left: 35px; clear: both"
                action="#"
                list-type="picture-card"
                :auto-upload="false"
                :file-list="fileListTicket"
                :on-change="handleChangePictureTicket"
                :on-remove="handleRemoveTicket"
                :before-remove="beforeRemove"
                :on-preview="handlePictureCardPreviewTicket"
                multiple
                ref="uploadTicket"
            >
              <i slot="default" class="el-icon-plus" style="font-style: normal; white-space: nowrap">Thêm Ảnh</i>
            </el-upload>
            <el-dialog width="50%" :visible.sync="dialogVisibleTicket">
              <img width="100%" :src="dialogImageUrlTicket" alt/>
            </el-dialog>
          </template>

          <el-row>
            <span><b style="font-size: 17px">Danh sách: {{ titleTicket }}</b></span>
          </el-row>
          <hr/>
          <el-row style="
             display: flex;
             flex-wrap: wrap;
             width: 100%;
             margin-top: 10px;
             border: 1px solid rgb(96 99 102 / 19%);
             max-height: 600px;
             padding-left: 50px;"
          >
            <div
                style="
                  display: flex;
                  width: 100%;
                  overflow-x: hidden;
                  max-height: 600px"
            >
              <div
                  style="
                  display: flex;
                  transition: all 0.5s linear"
                  :style="{transform: `translateX(-${currentSlideIndex * (100 / (this.listTicketUpdate.length / this.itemsToShow))}%)`}"
              >
                <div v-for="item in listTicketUpdate" :key="item.id"
                     style="flex: 0 0 223px; margin: 30px; width: 223px">
                  <div class="inline-block-div">
                    <el-image
                        :src="item.urlPicture"
                        style="width: 200px; height: 200px"
                        @click="handleShowImageDialog(item.urlPicture)"
                    ></el-image>
                    <span class="el-upload-list__item-actions">
                  <el-button
                      type="success"
                      class="el-upload-list__item-preview"
                      @click="deletePicture(item.id)"
                      style="float: left; height: 40px; width: 200px"
                  >
                    <i style="color: white" class="el-icon-delete-solid"></i>
                  </el-button>
                </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="container_btn_nav" v-if="listTicketUpdate.length > 0">
              <span
                  class="btn_nav btn-next"
                  style="
                  right: -18px;
                  top: 50%;
                  position: absolute"
                  @click="nextSlide"
                  :style="{cursor: currentSlideIndex === maxIndex ? 'not-allowed' : 'pointer'}"
              >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24" height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="lucide lucide-chevron-right"
                >
                  <path d="m9 18 6-6-6-6"/>
                </svg>
              </span>

              <span
                  class="btn_nav btn-prev"
                  style="
                  left: -18px;
                  top: 50%;
                  position: absolute"
                  @click="prevSlide"
                  :style="{cursor: currentSlideIndex === 0 ? 'not-allowed' : 'pointer'}"
              >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24" height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="lucide lucide-chevron-left"
                >
                  <path d="m15 18-6-6 6-6"/>
                </svg>
              </span>
            </div>
          </el-row>
        </div>

        <div class="tab-infor-button">
          <el-button
              type="success"
              size="medium"
              :loading="loadingButton"
              mini
              @click="submitForm('updateTemplateAppRequest')"
          >
            <i class="el-icon-circle-check"/>
            <span>Lưu</span>
          </el-button>
          <el-button
              type="danger"
              size="medium"
              @click="closeDialog()"
          >
            <i class="el-icon-circle-close"/>
            <span>Đóng</span>
          </el-button>
        </div>
      </el-form>
    </el-dialog>

    <el-dialog width="30%" :visible.sync="dialogVisibleTemplateDefault">
      <img width="100%" :src="urlTemplateDefault" alt/>
    </el-dialog>

    <el-dialog width="30%" :visible.sync="currentImageDialog.isVisibleDialog">
      <img width="100%" :src="currentImageDialog.currentImageUrl" alt/>
    </el-dialog>
  </div>

</template>

<script>
import {mapActions, mapState} from "vuex";
import SystemConfigService from "@/services/AdminService/SystemConfigService";
import {
  listDisplayTicket,
  listTicketRequest,
  parentScreenList,
  plusScreenList,
  teacherScreenList
} from "@/constant/listTemplate";
import templateConfigMixin from "@/global/mixinTemplate/templateConfig.mixin";

export default {
  components: {},
  props: {
    isOpenUpdateTemplateAppDialog: {
      type: Boolean,
      default: () => false,
    },
  },

  data() {
    return {
      currentSlideIndex: 0,
      itemsToShow: 5,
      currentImageDialog: {
        currentImageUrl: "",
        isVisibleDialog: false,
      },
      dialogVisibleTemplateDefault: false,
      urlTemplateDefault: '',
      agentList: "",
      checkedKeepPhoto: true,
      disabled: false,
      loadingData: true,
      loadingButton: false,
      dialogVisiblePicture: false,
      showUpload: false,
      version: 0,
      updateTemplateAppRequest: {
        id: "",
        nameTemplate: "",
        descriptionTemplate: "",
        appType: "plus",
        listMap: {},
        version: ""

      },
      plusScreenList,
      teacherScreenList,
      parentScreenList,
      listIdDelete: [],
      listDisplayTicket,
      displayTicket: 1,
      typePackage: "template",
      // listTicketLearnShow: [],
      listTicketEatShow: [],
      listTicketSleepShow: [],
      listTicketToiletShow: [],
      listTicketHealthyShow: [],
      listTicketCommentShow: [],
      listTicketWeekShow: [],
      listTicketMonthShow: [],
      listTicketPeriodicShow: [],
      listTicketRequest,
      titleTicket: '',
      listTicketUpdate: [],
      fileListTicket: [],
      dialogVisibleTicket: false,
      dialogImageUrlTicket: '',
      listTicketLearn: [],
      listTicketEat: [],
      listTicketSleep: [],
      listTicketToilet: [],
      listTicketHealthy: [],
      listTicketComment: [],
      listTicketWeek: [],
      listTicketMonth: [],
      listTicketPeriodic: [],
      listIdTicketDelete: [],
      validatorVersion: (rule, value, callback) => {
        let arr = this.updateTemplateAppRequest.version.split(".");
        if (value === undefined) {
          callback(new Error('Không được để trống'));
        } else if (value === parseInt(arr[arr.length - 1])) {
          callback(new Error('Chưa thay đổi phiên bản mới!'));
        } else {
          callback();
        }
      },
      rules: {
        nameTemplate: [
          {
            required: true,
            message: "Không được để trống",
            trigger: "blur",
          },
        ],
        descriptionTemplate: [
          {
            required: true,
            message: "Không được để trống",
            trigger: "blur",
          },
        ],
        idSchool: [
          {
            required: true,
            message: "Phải chọn trường",
            trigger: "change",
          },
        ],
        version: [
          {
            required: true,
            message: "Không được để trống",
            trigger: "change",
          }
        ]
      },
    };
  },
  mixins: [
    templateConfigMixin
  ],
  computed: {
    ...mapState('schoolStore', ['agentList']),
    ...mapState('schoolStore', ['schoolList']),
    maxIndex() {
      return Math.ceil(this.listTicketUpdate.length / this.itemsToShow) - 1;
    }
  },
  methods: {
    ...mapActions('schoolStore', ["fetchDataSchoolList"]),
    nextSlide() {
      if (this.currentSlideIndex < this.maxIndex) {
        ++this.currentSlideIndex;
      }
    },
    prevSlide() {
      if (this.currentSlideIndex > 0) {
        --this.currentSlideIndex;
      } else {
        this.currentSlideIndex = -this.maxIndex;
      }
    },
    handleShowImageDialog(url) {
      this.currentImageDialog.currentImageUrl = url;
      this.currentImageDialog.isVisibleDialog = true;
    },
    getFileList(file) {
      return file ? [file] : [];
    },
    showTemplateDefault(url) {
      this.dialogVisibleTemplateDefault = true
      this.urlTemplateDefault = url
    },
    tableHeaderColor() {
      return "background-color: #78a5e7;color: #fff;font-weight: bold;";
    },

    closeDialog() {
      this.clearAllData();
      this.$refs["updateTemplateAppRequest"].resetFields();
      this.$emit("dialog-close");
      this.currentSlideIndex = 0;
    },
    closeDialogByButton(fromData) {
      this.clearAllData();
      this.$refs[fromData].resetFields();
      this.$emit("dialog-close");
    },

    clearAllData() {
      this.listTicketLearn = []
      this.listTicketEat = []
      this.listTicketSleep = []
      this.listTicketToilet = []
      this.listTicketHealthy = []
      this.listTicketComment = []
      this.listTicketWeek = []
      this.listTicketMonth = []
      this.listTicketPeriodic = []
      this.typePackage = "template"
      this.listTicketUpdate = []
      this.fileListTicket = []
      this.listIdTicketDelete = []
      this.listIdDelete = []
      this.agentList = ""
      this.checkedKeepPhoto = true
      this.loadingData = true
      this.loadingButton = false
      this.dialogVisiblePicture = false
      this.showUpload = false
      this.updateTemplateAppRequest =
          {
            idSchool: "",
            nameSchool: "",
            nameTemplate: "",
            descriptionTemplate: "",
            appType: "plus",
            listMap: {},
          }
      this.handleClearList()
    },
// {key: "home", value: "Trang chủ", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    handleClearList() {
      for (let i = 0; i < this.plusScreenList.length; i++) {
        this.plusScreenList[i].file = null;
        this.plusScreenList[i].url = '';
        this.plusScreenList[i].showFile = true;
        this.plusScreenList[i].id = null
        this.plusScreenList[i].showFile = true
        this.plusScreenList[i].disabled = false
        this.plusScreenList[i].urlKeep = ''
      }
      for (let i = 0; i < this.teacherScreenList.length; i++) {
        this.teacherScreenList[i].file = null;
        this.teacherScreenList[i].url = "";
        this.teacherScreenList[i].showFile = true;
        this.teacherScreenList[i].id = null
        this.teacherScreenList[i].showFile = true
        this.teacherScreenList[i].disabled = false
        this.teacherScreenList[i].urlKeep = ''
      }
      for (let i = 0; i < this.parentScreenList.length; i++) {
        this.parentScreenList[i].file = null;
        this.parentScreenList[i].url = '';
        this.parentScreenList[i].showFile = true;
        this.teacherScreenList[i].id = null
        this.teacherScreenList[i].showFile = true
        this.teacherScreenList[i].disabled = false
        this.teacherScreenList[i].urlKeep = ''
      }
    },
    async getDataInitialUpdateTemplateDialog(row) {
      let idTemplate = row.id
      this.updateTemplateAppRequest.id = row.id;
      this.updateTemplateAppRequest.descriptionTemplate = row.descriptionTemplate;
      this.updateTemplateAppRequest.nameTemplate = row.nameTemplate;
      this.updateTemplateAppRequest.appType = row.appType;
      this.updateTemplateAppRequest.version = row.version;
      // Tách chuỗi dựa trên dấu chấm
      let parts = this.updateTemplateAppRequest.version.split('.');
      this.version = parseInt(parts[parts.length - 1], 10);
      let dataList = [];
      await SystemConfigService.getDetailTemplateSystem(idTemplate)
          .then((resp) => {
            dataList = resp.data.data.listTemplate;
            this.listTicketLearnShow = resp.data.data.listTicketLearn
            this.listTicketEatShow = resp.data.data.listTicketEat
            this.listTicketSleepShow = resp.data.data.listTicketSleep
            this.listTicketToiletShow = resp.data.data.listTicketToilet
            this.listTicketHealthyShow = resp.data.data.listTicketHealthy
            this.listTicketCommentShow = resp.data.data.listTicketComment
            this.listTicketWeekShow = resp.data.data.listTicketWeek
            this.listTicketMonthShow = resp.data.data.listTicketMonth
            this.listTicketPeriodicShow = resp.data.data.listTicketPeriodic
            if (this.updateTemplateAppRequest.appType === "plus") {
              for (let i = 0; i < this.plusScreenList.length; i++) {
                let plusTemplate = this.plusScreenList[i];
                let key = this.plusScreenList[i].key;
                for (let j = 0; j < dataList.length; j++) {
                  let data = dataList[j];
                  if (data.namePicture === key) {
                    plusTemplate.id = data.id
                    plusTemplate.url = data.urlPicture
                    plusTemplate.urlKeep = data.urlPicture
                    plusTemplate.showFile = true
                  }
                }
              }

            } else if (this.updateTemplateAppRequest.appType === "teacher") {
              for (let i = 0; i < this.teacherScreenList.length; i++) {
                let teacherTemplate = this.teacherScreenList[i];
                let key = this.teacherScreenList[i].key;
                for (let j = 0; j < dataList.length; j++) {
                  let data = dataList[j];
                  if (data.namePicture === key) {
                    teacherTemplate.id = data.id
                    teacherTemplate.url = data.urlPicture
                    teacherTemplate.urlKeep = data.urlPicture
                    teacherTemplate.showFile = true
                  }
                }
              }
            } else {
              for (let i = 0; i < this.parentScreenList.length; i++) {
                let parentTemplate = this.parentScreenList[i];
                let key = this.parentScreenList[i].key;
                for (let j = 0; j < dataList.length; j++) {
                  let data = dataList[j];
                  if (data.namePicture === key) {
                    parentTemplate.id = data.id
                    parentTemplate.url = data.urlPicture
                    parentTemplate.urlKeep = data.urlPicture
                    parentTemplate.showFile = true
                  }
                }
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },

    submitForm(fromData) {
      this.$refs[fromData].validate((valid) => {
        if (valid) {
          const formData = new FormData();
          formData.append("id", this.updateTemplateAppRequest.id);
          formData.append("nameTemplate", this.updateTemplateAppRequest.nameTemplate);
          formData.append("descriptionTemplate", this.updateTemplateAppRequest.descriptionTemplate);
          formData.append("appType", this.updateTemplateAppRequest.appType);
          // Tách chuỗi dựa trên dấu chấm
          let parts = this.updateTemplateAppRequest.version.split('.');
          parts[parts.length - 1] = this.version.toString();
          this.updateTemplateAppRequest.version = parts.join('.');
          formData.append("version", this.updateTemplateAppRequest.version);
          if (this.listIdTicketDelete.length >= 0) {
            this.listIdDelete.push(...this.listIdTicketDelete)
          }
          formData.append("listIdDelete", this.listIdDelete);
          if (this.updateTemplateAppRequest.appType === "plus") {
            for (let i = 0; i < this.plusScreenList.length; i++) {
              if (this.plusScreenList[i].file != null) {
                formData.append(`listMap[${this.plusScreenList[i].key}]`, this.plusScreenList[i].file.raw);
              }
            }
          } else if (this.updateTemplateAppRequest.appType === "teacher") {
            for (let i = 0; i < this.teacherScreenList.length; i++) {
              if (this.teacherScreenList[i].file != null) {
                formData.append(`listMap[${this.teacherScreenList[i].key}]`, this.teacherScreenList[i].file.raw);
              }
            }
          } else {
            for (let i = 0; i < this.parentScreenList.length; i++) {
              if (this.parentScreenList[i].file != null) {
                formData.append(`listMap[${this.parentScreenList[i].key}]`, this.parentScreenList[i].file.raw);
              }
            }
          }
          if (this.updateTemplateAppRequest.appType === "plus") {
            this.addImageTicketFormData(formData, this.listTicketLearn, this.listTicketEat, this.listTicketSleep, this.listTicketToilet,
                this.listTicketHealthy, this.listTicketComment, this.listTicketWeek, this.listTicketMonth, this.listTicketPeriodic)
          }
          this.loadingButton = true;
          SystemConfigService.updateTemplateSystem(formData)
              .then((resp) => {
                this.$message({
                  message: resp.data.message,
                  type: "success",
                });
                setTimeout(() => {
                  this.closeDialog();
                }, 500);
              })
              .catch((err) => {
                this.$message({
                  message: err.response.data.message,
                  type: "error",
                });
              })
              .finally(() => {
                setTimeout(() => {
                  this.loadingButton = false;
                }, 500);
              });
        }
      });
    },

    handleRemove(row, file) {
      console.log(file)
      if (this.updateTemplateAppRequest.appType === "plus") {
        for (let i = 0; i < this.plusScreenList.length; i++) {
          if (this.plusScreenList[i].key === row.key) {
            this.plusScreenList[i].file = null;
            this.plusScreenList[i].url = '';
            this.plusScreenList[i].showFile = true;
          }
        }
      } else if (this.updateTemplateAppRequest.appType === "teacher") {
        for (let i = 0; i < this.teacherScreenList.length; i++) {
          if (this.teacherScreenList[i].key === row.key) {
            this.teacherScreenList[i].file = null;
            this.teacherScreenList[i].url = "";
            this.teacherScreenList[i].showFile = true;
          }
        }
      } else {
        for (let i = 0; i < this.parentScreenList.length; i++) {
          if (this.parentScreenList[i].key === row.key) {
            this.parentScreenList[i].file = null;
            this.parentScreenList[i].url = '';
            this.parentScreenList[i].showFile = true;
          }
        }
      }
    },

    beforeRemove(file) {
      return this.$confirm(`Cancel the transfert of ${file.name} ?`);
    },
    handlePictureCardPreview(row, file) {
      if (this.updateTemplateAppRequest.appType === "plus") {
        for (let i = 0; i < this.plusScreenList.length; i++) {
          if (this.plusScreenList[i].key === row.key) {
            this.plusScreenList[i].url = file.url;
            this.plusScreenList[i].dialogImg = true;
          }
        }
      } else if (this.updateTemplateAppRequest.appType === "teacher") {
        for (let i = 0; i < this.teacherScreenList.length; i++) {
          if (this.teacherScreenList[i].key === row.key) {
            this.teacherScreenList[i].url = file.url;
            this.teacherScreenList[i].dialogImg = true;
          }
        }
      } else {
        for (let i = 0; i < this.parentScreenList.length; i++) {
          if (this.parentScreenList[i].key === row.key) {
            this.parentScreenList[i].url = file.url;
            this.parentScreenList[i].dialogImg = true;
          }
        }
      }
    },

    deleteTemplateSystem(id) {
      console.log("id xóa: ", id)
      this.listIdDelete.push(id)
      for (let i = 0; i < this.plusScreenList.length; i++) {
        if (this.plusScreenList[i].id === id) {
          this.plusScreenList[i].urlKeep = ''
        }
      }
      for (let i = 0; i < this.teacherScreenList.length; i++) {
        if (this.teacherScreenList[i].id === id) {
          this.teacherScreenList[i].urlKeep = ''
        }
      }
      for (let i = 0; i < this.parentScreenList.length; i++) {
        if (this.parentScreenList[i].id === id) {
          this.parentScreenList[i].urlKeep = ''
        }
      }
    },

    handleChangePicture(row, file) {
      if (this.updateTemplateAppRequest.appType === "plus") {
        for (let i = 0; i < this.plusScreenList.length; i++) {
          if (this.plusScreenList[i].key === row.key) {
            this.plusScreenList[i].file = file;
            this.plusScreenList[i].url = file.url;
            this.plusScreenList[i].showFile = false;
          }
        }
      } else if (this.updateTemplateAppRequest.appType === "teacher") {
        for (let i = 0; i < this.teacherScreenList.length; i++) {
          if (this.teacherScreenList[i].key === row.key) {
            this.teacherScreenList[i].file = file;
            this.teacherScreenList[i].url = file.url;
            this.teacherScreenList[i].showFile = false;
          }
        }
      } else {
        for (let i = 0; i < this.parentScreenList.length; i++) {
          if (this.parentScreenList[i].key === row.key) {
            this.parentScreenList[i].file = file;
            this.parentScreenList[i].url = file.url;
            this.parentScreenList[i].showFile = false;
          }
        }
      }
      this.checkedKeepPhoto = false
      const isJPG = file.raw.type === "image/jpeg";
      const isPNG = file.raw.type === "image/png";
      const isLt5M = file.size / 1024 / 1024 < 5;

      if (!isJPG && !isPNG) {
        this.$message.error("Ảnh phải có dạng JPG hoặc PNG!");
        this.handleRemove(file);
      }
      if (!isLt5M) {
        this.$message.error("Ảnh không thể vượt quá 5MB");
        this.handleRemove(file);
      }
      return isJPG && isLt5M;
    },

    handleChangeDisplayTicket() {
      this.listTicketUpdate = []
      this.titleTicket = this.getNameTicket(this.displayTicket)
      switch (this.displayTicket) {
        case 1: {
          this.listTicketUpdate = this.listTicketLearnShow
          this.fileListTicket = this.listTicketLearn
          break;
        }
        case 2: {
          this.listTicketUpdate = this.listTicketEatShow
          this.fileListTicket = this.listTicketEat
          break;
        }
        case 3: {
          this.listTicketUpdate = this.listTicketSleepShow
          this.fileListTicket = this.listTicketSleep
          break;
        }
        case 4: {
          this.listTicketUpdate = this.listTicketToiletShow
          this.fileListTicket = this.listTicketToilet
          break;
        }
        case 5: {
          this.listTicketUpdate = this.listTicketHealthyShow
          this.fileListTicket = this.listTicketHealthy
          break;
        }
        case 6: {
          this.listTicketUpdate = this.listTicketCommentShow
          this.fileListTicket = this.listTicketComment
          break;
        }
        case 7: {
          this.listTicketUpdate = this.listTicketWeekShow
          this.fileListTicket = this.listTicketWeek
          break;
        }
        case 8: {
          this.listTicketUpdate = this.listTicketMonthShow
          this.fileListTicket = this.listTicketMonth
          break;
        }
        case 9: {
          this.listTicketUpdate = this.listTicketPeriodicShow
          this.fileListTicket = this.listTicketPeriodic
          break;
        }
      }
    },
    handleChangeTypeTicket() {
      this.currentSlideIndex = 0;
      if (this.typePackage === 'ticket') {
        this.handleChangeDisplayTicket()
      }
    },

    // Chọn ảnh phiếu bé ngoan
    handleChangePictureTicket(file, fileList) {
      this.fileListTicket = fileList
      switch (this.displayTicket) {
        case 1: {
          this.listTicketLearn = this.fileListTicket
          break;
        }
        case 2: {
          this.listTicketEat = this.fileListTicket
          break;
        }
        case 3: {
          this.listTicketSleep = this.fileListTicket
          break;
        }
        case 4: {
          this.listTicketToilet = this.fileListTicket
          break;
        }
        case 5: {
          this.listTicketHealthy = this.fileListTicket
          break;
        }
        case 6: {
          this.listTicketComment = this.fileListTicket
          break;
        }
        case 7: {
          this.listTicketWeek = this.fileListTicket
          break;
        }
        case 8: {
          this.listTicketMonth = this.fileListTicket
          break;
        }
        case 9: {
          this.listTicketPeriodic = this.fileListTicket
          break;
        }
      }
      const isJPG = file.raw.type === "image/jpeg";
      const isPNG = file.raw.type === "image/png";
      const isLt5M = file.size / 1024 / 1024 < 5;

      if (!isJPG && !isPNG) {
        this.$message.error("Ảnh phải có dạng JPG hoặc PNG!");
        this.handleRemoveTicket(file);
        return false; // Trả về false để không thực hiện tiếp tục xử lý
      }
      if (!isLt5M) {
        this.$message.error("Ảnh không thể vượt quá 5MB");
        this.handleRemoveTicket(file);
        return false; // Trả về false để không thực hiện tiếp tục xử lý
      }
      return true;
    },
    // Xem chi tiết ảnh phiếu bé ngoan
    handlePictureCardPreviewTicket(file) {
      this.dialogImageUrlTicket = file.url;
      this.dialogVisibleTicket = true;
    },
    // Xóa file phiếu bé ngoan
    handleRemoveTicket(file) {
      this.$refs.uploadTicket.clearFiles();
      let index;
      for (let i = 0; i < this.fileListTicket.length; i++) {
        if (file.uid === this.fileListTicket[i].uid) {
          index = i
        }
      }
      if (index >= 0) {
        this.fileListTicket.splice(index, 1);
      }
      let indexTicket;
      switch (this.displayTicket) {
        case 1: {
          for (let i = 0; i < this.listTicketLearn.length; i++) {
            if (file.uid === this.listTicketLearn[i].uid) {
              indexTicket = i
            }
          }
          if (indexTicket >= 0) {
            this.listTicketLearn.splice(indexTicket, 1);
          }
          break;
        }
        case 2: {
          for (let i = 0; i < this.listTicketEat.length; i++) {
            if (file.uid === this.listTicketEat[i].uid) {
              indexTicket = i
            }
          }
          if (indexTicket >= 0) {
            this.listTicketEat.splice(indexTicket, 1);
          }
          break;
        }
        case 3: {
          for (let i = 0; i < this.listTicketSleep.length; i++) {
            if (file.uid === this.listTicketSleep[i].uid) {
              indexTicket = i
            }
          }
          if (indexTicket >= 0) {
            this.listTicketSleep.splice(indexTicket, 1);
          }
          break;
        }
        case 4: {
          for (let i = 0; i < this.listTicketToilet.length; i++) {
            if (file.uid === this.listTicketToilet[i].uid) {
              indexTicket = i
            }
          }
          if (indexTicket >= 0) {
            this.listTicketToilet.splice(indexTicket, 1);
          }
          break;
        }
        case 5: {
          for (let i = 0; i < this.listTicketHealthy.length; i++) {
            if (file.uid === this.listTicketHealthy[i].uid) {
              indexTicket = i
            }
          }
          if (indexTicket >= 0) {
            this.listTicketHealthy.splice(indexTicket, 1);
          }
          break;
        }
        case 6: {
          for (let i = 0; i < this.listTicketComment.length; i++) {
            if (file.uid === this.listTicketComment[i].uid) {
              indexTicket = i
            }
          }
          if (indexTicket >= 0) {
            this.listTicketComment.splice(indexTicket, 1);
          }
          break;
        }
        case 7: {
          for (let i = 0; i < this.listTicketWeek.length; i++) {
            if (file.uid === this.listTicketWeek[i].uid) {
              indexTicket = i
            }
          }
          if (indexTicket >= 0) {
            this.listTicketWeek.splice(indexTicket, 1);
          }
          break;
        }
        case 8: {
          for (let i = 0; i < this.listTicketMonth.length; i++) {
            if (file.uid === this.listTicketMonth[i].uid) {
              indexTicket = i
            }
          }
          if (indexTicket >= 0) {
            this.listTicketMonth.splice(indexTicket, 1);
          }
          break;
        }
        case 9: {
          for (let i = 0; i < this.listTicketPeriodic.length; i++) {
            if (file.uid === this.listTicketPeriodic[i].uid) {
              indexTicket = i
            }
          }
          if (indexTicket >= 0) {
            this.listTicketPeriodic.splice(indexTicket, 1);
          }
          break;
        }
      }
    },

    deletePicture(id) {
      this.$confirm("Bạn có chắc chắn muốn xóa ảnh đã chọn?", "Thông báo!", {
        distinguishCancelAndClose: true,
        confirmButtonText: "Có",
        closeOnClickModal: false,
        cancelButtonText: "Không",
      }).then(() => {
        let indexTicket;
        switch (this.displayTicket) {
          case 1: {
            for (let i = 0; i < this.listTicketLearnShow.length; i++) {
              if (id === this.listTicketLearnShow[i].id) {
                indexTicket = i
              }
            }
            if (indexTicket >= 0) {
              this.listTicketLearnShow.splice(indexTicket, 1);
            }
            break;
          }
          case 2: {
            for (let i = 0; i < this.listTicketEatShow.length; i++) {
              if (id === this.listTicketEatShow[i].id) {
                indexTicket = i
              }
            }
            if (indexTicket >= 0) {
              this.listTicketEatShow.splice(indexTicket, 1);
            }
            break;
          }
          case 3: {
            for (let i = 0; i < this.listTicketSleepShow.length; i++) {
              if (id === this.listTicketSleepShow[i].id) {
                indexTicket = i
              }
            }
            if (indexTicket >= 0) {
              this.listTicketSleepShow.splice(indexTicket, 1);
            }
            break;
          }
          case 4: {
            for (let i = 0; i < this.listTicketToiletShow.length; i++) {
              if (id === this.listTicketToiletShow[i].id) {
                indexTicket = i
              }
            }
            if (indexTicket >= 0) {
              this.listTicketToiletShow.splice(indexTicket, 1);
            }
            break;
          }
          case 5: {
            for (let i = 0; i < this.listTicketHealthyShow.length; i++) {
              if (id === this.listTicketHealthyShow[i].id) {
                indexTicket = i
              }
            }
            if (indexTicket >= 0) {
              this.listTicketHealthyShow.splice(indexTicket, 1);
            }
            break;
          }
          case 6: {
            for (let i = 0; i < this.listTicketCommentShow.length; i++) {
              if (id === this.listTicketCommentShow[i].id) {
                indexTicket = i
              }
            }
            if (indexTicket >= 0) {
              this.listTicketCommentShow.splice(indexTicket, 1);
            }
            break;
          }
          case 7: {
            for (let i = 0; i < this.listTicketWeekShow.length; i++) {
              if (id === this.listTicketWeekShow[i].id) {
                indexTicket = i
              }
            }
            if (indexTicket >= 0) {
              this.listTicketWeekShow.splice(indexTicket, 1);
            }
            break;
          }
          case 8: {
            for (let i = 0; i < this.listTicketMonthShow.length; i++) {
              if (id === this.listTicketMonthShow[i].id) {
                indexTicket = i
              }
            }
            if (indexTicket >= 0) {
              this.listTicketMonthShow.splice(indexTicket, 1);
            }
            break;
          }
          case 9: {
            for (let i = 0; i < this.listTicketPeriodicShow.length; i++) {
              if (id === this.listTicketPeriodicShow[i].id) {
                indexTicket = i
              }
            }
            if (indexTicket >= 0) {
              this.listTicketPeriodicShow.splice(indexTicket, 1);
            }
            break;
          }
        }
        this.listIdTicketDelete.push(id)
        this.$message({
          message: "Xóa ảnh thành công",
          type: "success",
        });
      });
    },

    handleTypeApp() {
      if (this.updateTemplateAppRequest.appType !== 'plus') {
        this.typePackage = 'template'
      }
    },

  },
};
</script>
<style>
.hideUpload > div {
  visibility: hidden;
}
</style>
<style lang="scss" scoped>

/deep/ .el-dialog__title {
  font-size: 25px;
  color: #606266;
}

/deep/ .el-form-item__label {
  font-family: Arial, Helvetica, sans-serif;
}

/deep/ .el-button--primary {
  width: 100%;
  background: #409eff;
}

/deep/ .upload-demo {
  text-align: center;

  .el-upload {
    width: 100%;
  }
}

/deep/ .el-avatar {
  display: inline;

  img {
    border-radius: 5px;
    width: 100%;
  }
}

/deep/ .el-input.is-disabled .el-input__inner {
  text-align: center;
  color: #d13b93;
  font-weight: bold;
}

/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.el-select {
  display: block;
}

.tab-infor-button {
  text-align: right;
  margin-top: 20px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.group-radio-status {
  margin: 0 50px;

  .kids-status {
    margin-bottom: 10px;
  }
}

/deep/ .el-tabs__nav {
  background: #a0a19ce0;
}

/deep/ .el-tabs__item {
  color: white;
}

/deep/ .el-tabs__item.is-active {
  color: white;
  background: #67c23a;
}

/deep/ .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:after {
  content: "*";
  color: #f56c6c;
  margin-left: 3px;
}

/deep/ .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  content: "";
  color: #f56c6c;
  margin-left: -4px;
}

/deep/ .el-upload--picture-card {
  background: none;
  border-radius: 6px;
  border: none; /* Default border */
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 148px;
  height: 148px;
  cursor: pointer;
  line-height: 146px;
  vertical-align: top;
}

/deep/ .el-table th.gutter {
  background-color: #78a5e7;
}

/deep/ .el-image__error {
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #ffffff;
  vertical-align: middle;
}

.app_name {
  display: inline-block;
  margin: 0;
  text-transform: capitalize;
  font-size: 15px;
  padding: 0 12px;
  cursor: not-allowed;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/deep/ .el-upload-list__item.is-ready > div {
  height: 100%;
}

.tag_image {
  background: #409effe6;
  position: absolute;
  left: -20px;
  top: -4px;
  width: 60px;
  padding: 6px 0 0 0;
  text-align: center;
  color: #fff;
  rotate: -45deg;
  font-size: 12px;
  text-transform: capitalize;
}

/deep/ .el-image__inner {
  object-fit: cover;
}

.btn_nav {
  background-color: #ecf4ff;
  display: flex;
  padding: 6px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.8s ease-in-out;
}

.btn_nav:hover {
  background-color: #d3e4fb;
}
</style>
