var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Chi tiết gói giao diện",
            visible: _vm.isOpenDetailTemplateAppDialog,
            width: "1000px",
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            top: "3vh",
            border: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isOpenDetailTemplateAppDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "detailTemplateData",
              attrs: {
                "label-width": "140px",
                model: _vm.detailTemplateData,
                "label-position": "left",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 50 } },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "Tên template",
                              prop: "nameTemplate",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                readonly: "",
                                placeholder: "Nhập tên template",
                              },
                              model: {
                                value: _vm.detailTemplateData.nameTemplate,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.detailTemplateData,
                                    "nameTemplate",
                                    $$v
                                  )
                                },
                                expression: "detailTemplateData.nameTemplate",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "Mô tả",
                              prop: "descriptionTemplate",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                readonly: "",
                                placeholder: "Nhập mô tả",
                              },
                              model: {
                                value:
                                  _vm.detailTemplateData.descriptionTemplate,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.detailTemplateData,
                                    "descriptionTemplate",
                                    $$v
                                  )
                                },
                                expression:
                                  "detailTemplateData.descriptionTemplate",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 50 } },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "Loại App", prop: "appType" } },
                          [
                            _c("h4", { staticClass: "app_name" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.detailTemplateData.appType) +
                                  "\n              "
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm.detailTemplateData.appType === "plus"
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticStyle: { "margin-top": "12px" },
                              model: {
                                value: _vm.typePackage,
                                callback: function ($$v) {
                                  _vm.typePackage = $$v
                                },
                                expression: "typePackage",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "template" } }, [
                                _vm._v("Giao diện"),
                              ]),
                              _c(
                                "el-radio",
                                {
                                  staticStyle: { "margin-left": "28px" },
                                  attrs: { label: "ticket" },
                                },
                                [_vm._v("Phiếu bé ngoan")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.typePackage === "template"
                ? _c(
                    "el-table",
                    {
                      ref: "multipleTable",
                      attrs: {
                        "element-loading-spinner": "el-icon-loading",
                        "element-loading-background": "rgba(255,255,255, 0)",
                        "highlight-current-row": "",
                        data: _vm.screenList,
                        "header-cell-style": _vm.tableHeaderColor,
                        "max-height": _vm.$tableMaxHeight,
                        border: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          label: "STT",
                          width: "80",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "Màn hình", "min-width": "150" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [_vm._v(_vm._s(scope.row.value))]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1320824237
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Ảnh",
                          width: "150px",
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    [
                                      _c("el-image", {
                                        staticClass: "el-image-opacity",
                                        staticStyle: {
                                          width: "50px",
                                          height: "50px",
                                        },
                                        attrs: {
                                          src: scope.row.url,
                                          fit: _vm.contain,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.showImageTemplate(
                                              scope.row
                                            )
                                          },
                                        },
                                      }),
                                      _c(
                                        "el-dialog",
                                        {
                                          attrs: {
                                            modal: false,
                                            width: "30%",
                                            visible: scope.row.dialogImg,
                                          },
                                          on: {
                                            "update:visible": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                scope.row,
                                                "dialogImg",
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              width: "100%",
                                              src: scope.row.url,
                                              alt: "",
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3291795152
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "Link", "min-width": "150" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [_vm._v(_vm._s(scope.row.url))]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3700262509
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.typePackage === "ticket"
                ? _c(
                    "el-row",
                    {
                      staticStyle: {
                        display: "flex",
                        "flex-wrap": "wrap",
                        width: "100%",
                        "margin-top": "10px",
                        border: "1px solid rgb(96 99 102 / 19%)",
                        "overflow-y": "scroll",
                        "max-height": "600px",
                        "padding-left": "50px",
                      },
                    },
                    _vm._l(_vm.listTicket, function (item) {
                      return _c(
                        "div",
                        {
                          key: item.id,
                          staticStyle: {
                            flex: "0 0 223px",
                            margin: "30px",
                            width: "223px",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "inline-block-div" },
                            [
                              _c("el-image", {
                                staticStyle: {
                                  width: "250px",
                                  height: "200px",
                                },
                                attrs: { src: item.url },
                                on: {
                                  click: function ($event) {
                                    return _vm.showListImageTicket(item)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          item.numberPicture !== 0
                            ? _c("div", [
                                _c(
                                  "h4",
                                  {
                                    staticStyle: {
                                      color: "#30313499",
                                      "text-align": "left",
                                      "margin-left": "5px",
                                      overflow: "hidden",
                                      "max-height": "20px",
                                      "text-overflow": "ellipsis",
                                      "white-space": "nowrap",
                                    },
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass: "demonstration",
                                    staticStyle: {
                                      "margin-right": "5px",
                                      float: "left",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(item.numberPicture) +
                                        "\n              "
                                    ),
                                    _c("i", {
                                      staticClass: "el-icon-picture",
                                      staticStyle: { color: "#6298d0" },
                                    }),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "tab-infor-button" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger", size: "medium" },
                      on: {
                        click: function ($event) {
                          return _vm.closeDialogByButton("detailTemplateData")
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-circle-close" }),
                      _c("span", [_vm._v("Đóng")]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("DetailTicketDialog", {
            attrs: {
              showDetailTicket: _vm.showDetailTicket,
              titleTicket: _vm.titleTicket,
              listTicketDetail: _vm.listTicketDetail,
            },
            on: { close: _vm.handelCloseDetailTicketDialog },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }