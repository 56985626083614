var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.isOpenAddTemplateAppDialog,
            width: "800px",
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            top: "3vh",
            title: "Thêm gói giao diện App",
            border: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isOpenAddTemplateAppDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "createTemplateAppRequest",
              attrs: {
                "label-width": "140px",
                model: _vm.createTemplateAppRequest,
                "label-position": "left",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 15 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "Tên template",
                              prop: "nameTemplate",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "Nhập tên template" },
                              model: {
                                value:
                                  _vm.createTemplateAppRequest.nameTemplate,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.createTemplateAppRequest,
                                    "nameTemplate",
                                    $$v
                                  )
                                },
                                expression:
                                  "createTemplateAppRequest.nameTemplate",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 15 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Mô tả",
                            prop: "descriptionTemplate",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "Nhập mô tả" },
                            model: {
                              value:
                                _vm.createTemplateAppRequest
                                  .descriptionTemplate,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.createTemplateAppRequest,
                                  "descriptionTemplate",
                                  $$v
                                )
                              },
                              expression:
                                "createTemplateAppRequest.descriptionTemplate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 15 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "appType", label: "Loại app" } },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.createTemplateAppRequest.appType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.createTemplateAppRequest,
                                      "appType",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "createTemplateAppRequest.appType",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: "plus" } }, [
                                  _vm._v("Plus"),
                                ]),
                                _c(
                                  "el-radio",
                                  {
                                    staticStyle: { "margin-left": "10px" },
                                    attrs: { label: "teacher" },
                                  },
                                  [_vm._v("Teacher")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    staticStyle: { "margin-left": "10px" },
                                    attrs: { label: "parent" },
                                  },
                                  [_vm._v("Onekids")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 15 } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.typePackage,
                                callback: function ($$v) {
                                  _vm.typePackage = $$v
                                },
                                expression: "typePackage",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "template" } }, [
                                _vm._v("Giao diện"),
                              ]),
                              _vm.createTemplateAppRequest.appType === "plus"
                                ? _c(
                                    "el-radio",
                                    {
                                      staticStyle: { "margin-left": "50px" },
                                      attrs: { label: "ticket" },
                                    },
                                    [_vm._v("Phiếu bé ngoan")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.typePackage === "ticket"
                ? _c(
                    "el-row",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Màn hình" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "200px" },
                              attrs: { placeholder: "Chọn màn hình" },
                              on: { change: _vm.handleChangeDisplayTicket },
                              model: {
                                value: _vm.displayTicket,
                                callback: function ($$v) {
                                  _vm.displayTicket = $$v
                                },
                                expression: "displayTicket",
                              },
                            },
                            _vm._l(_vm.listDisplayTicket, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                staticStyle: { width: "200px" },
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.createTemplateAppRequest.appType === "plus" &&
              _vm.typePackage === "template"
                ? _c(
                    "el-table",
                    {
                      ref: "multipleTable",
                      attrs: {
                        "element-loading-spinner": "el-icon-loading",
                        "element-loading-background": "rgba(255,255,255, 0)",
                        "highlight-current-row": "",
                        data: _vm.plusScreenList,
                        "header-cell-style": _vm.tableHeaderColor,
                        "max-height": 500,
                        border: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          label: "STT",
                          width: "80",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "Màn hình", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [_vm._v(_vm._s(scope.row.value))]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1320824237
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "Chọn ảnh" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-upload",
                                    {
                                      ref: "upload",
                                      attrs: {
                                        action: "/api/upload/",
                                        "list-type": "picture-card",
                                        "auto-upload": false,
                                        "file-list": _vm.getFileList(
                                          scope.row.file
                                        ),
                                        "on-change": (file) =>
                                          _vm.handleChangePicture(
                                            scope.row,
                                            file
                                          ),
                                        "on-remove": (file) =>
                                          _vm.handleRemove(scope.row, file),
                                        "before-remove": (file) =>
                                          _vm.beforeRemove(scope.row, file),
                                        accept: "image/jpeg,image/png",
                                        disabled: scope.row.showFile === false,
                                        limit: "1",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "file",
                                            fn: function ({ file }) {
                                              return _c("div", {}, [
                                                _c(
                                                  "span",
                                                  { staticClass: "tag_image" },
                                                  [_vm._v("New")]
                                                ),
                                                _c("img", {
                                                  staticClass:
                                                    "el-upload-list__item-thumbnail",
                                                  attrs: {
                                                    src: file.url,
                                                    alt: "",
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "el-upload-list__item-actions",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "el-upload-list__item-preview",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handlePictureCardPreview(
                                                              scope.row,
                                                              file
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-zoom-in",
                                                        }),
                                                      ]
                                                    ),
                                                    !scope.row.disabled
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "el-upload-list__item-delete",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleRemove(
                                                                  scope.row,
                                                                  file
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "el-icon-delete",
                                                            }),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ])
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      scope.row.showFile === true
                                        ? _c("div", [
                                            _c(
                                              "i",
                                              {
                                                staticClass: "el-icon-plus",
                                                staticStyle: {
                                                  "font-style": "normal",
                                                },
                                                attrs: { slot: "default" },
                                                slot: "default",
                                              },
                                              [_vm._v("Thêm ảnh")]
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c(
                                    "el-dialog",
                                    {
                                      attrs: {
                                        width: "30%",
                                        visible: scope.row.dialogImg,
                                      },
                                      on: {
                                        "update:visible": function ($event) {
                                          return _vm.$set(
                                            scope.row,
                                            "dialogImg",
                                            $event
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          width: "100%",
                                          src: scope.row.url,
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3740995302
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.createTemplateAppRequest.appType === "teacher" &&
              _vm.typePackage === "template"
                ? _c(
                    "el-table",
                    {
                      ref: "multipleTable",
                      attrs: {
                        "element-loading-spinner": "el-icon-loading",
                        "element-loading-background": "rgba(255,255,255, 0)",
                        "highlight-current-row": "",
                        data: _vm.teacherScreenList,
                        "header-cell-style": _vm.tableHeaderColor,
                        "max-height": 500,
                        border: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          label: "STT",
                          width: "80",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "Màn hình", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [_vm._v(_vm._s(scope.row.value))]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1320824237
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "Chọn ảnh" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-upload",
                                    {
                                      ref: "upload",
                                      attrs: {
                                        action: "/api/upload/",
                                        "list-type": "picture-card",
                                        "auto-upload": false,
                                        "file-list": _vm.getFileList(
                                          scope.row.file
                                        ),
                                        "on-change": (file) =>
                                          _vm.handleChangePicture(
                                            scope.row,
                                            file
                                          ),
                                        "on-remove": (file) =>
                                          _vm.handleRemove(scope.row, file),
                                        "before-remove": (file) =>
                                          _vm.beforeRemove(scope.row, file),
                                        accept: "image/jpeg,image/png",
                                        disabled: scope.row.showFile === false,
                                        limit: "1",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "file",
                                            fn: function ({ file }) {
                                              return _c("div", {}, [
                                                _c(
                                                  "span",
                                                  { staticClass: "tag_image" },
                                                  [_vm._v("New")]
                                                ),
                                                _c("img", {
                                                  staticClass:
                                                    "el-upload-list__item-thumbnail",
                                                  attrs: {
                                                    src: file.url,
                                                    alt: "",
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "el-upload-list__item-actions",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "el-upload-list__item-preview",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handlePictureCardPreview(
                                                              scope.row,
                                                              file
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-zoom-in",
                                                        }),
                                                      ]
                                                    ),
                                                    !scope.row.disabled
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "el-upload-list__item-delete",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleRemove(
                                                                  scope.row,
                                                                  file
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "el-icon-delete",
                                                            }),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ])
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      scope.row.showFile === true
                                        ? _c("div", [
                                            _c(
                                              "i",
                                              {
                                                staticClass: "el-icon-plus",
                                                staticStyle: {
                                                  "font-style": "normal",
                                                },
                                                attrs: { slot: "default" },
                                                slot: "default",
                                              },
                                              [_vm._v("Thêm ảnh")]
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c(
                                    "el-dialog",
                                    {
                                      attrs: {
                                        width: "30%",
                                        visible: scope.row.dialogImg,
                                      },
                                      on: {
                                        "update:visible": function ($event) {
                                          return _vm.$set(
                                            scope.row,
                                            "dialogImg",
                                            $event
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          width: "100%",
                                          src: scope.row.url,
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3740995302
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.createTemplateAppRequest.appType === "parent" &&
              _vm.typePackage === "template"
                ? _c(
                    "el-table",
                    {
                      ref: "multipleTable",
                      attrs: {
                        "element-loading-spinner": "el-icon-loading",
                        "element-loading-background": "rgba(255,255,255, 0)",
                        "highlight-current-row": "",
                        data: _vm.parentScreenList,
                        "header-cell-style": _vm.tableHeaderColor,
                        "max-height": 500,
                        border: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          label: "STT",
                          width: "80",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "Màn hình", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [_vm._v(_vm._s(scope.row.value))]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1320824237
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "Chọn ảnh" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-upload",
                                    {
                                      ref: "upload",
                                      attrs: {
                                        action: "/api/upload/",
                                        "list-type": "picture-card",
                                        "auto-upload": false,
                                        "file-list": _vm.getFileList(
                                          scope.row.file
                                        ),
                                        "on-change": (file) =>
                                          _vm.handleChangePicture(
                                            scope.row,
                                            file
                                          ),
                                        "on-remove": (file) =>
                                          _vm.handleRemove(scope.row, file),
                                        "before-remove": (file) =>
                                          _vm.beforeRemove(scope.row, file),
                                        accept: "image/jpeg,image/png",
                                        disabled: scope.row.showFile === false,
                                        limit: "1",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "file",
                                            fn: function ({ file }) {
                                              return _c("div", {}, [
                                                _c(
                                                  "span",
                                                  { staticClass: "tag_image" },
                                                  [_vm._v("New")]
                                                ),
                                                _c("img", {
                                                  staticClass:
                                                    "el-upload-list__item-thumbnail",
                                                  attrs: {
                                                    src: file.url,
                                                    alt: "",
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "el-upload-list__item-actions",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "el-upload-list__item-preview",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handlePictureCardPreview(
                                                              scope.row,
                                                              file
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-zoom-in",
                                                        }),
                                                      ]
                                                    ),
                                                    !scope.row.disabled
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "el-upload-list__item-delete",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleRemove(
                                                                  scope.row,
                                                                  file
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "el-icon-delete",
                                                            }),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ])
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      scope.row.showFile === true
                                        ? _c("div", [
                                            _c(
                                              "i",
                                              {
                                                staticClass: "el-icon-plus",
                                                staticStyle: {
                                                  "font-style": "normal",
                                                },
                                                attrs: { slot: "default" },
                                                slot: "default",
                                              },
                                              [_vm._v("Thêm ảnh")]
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c(
                                    "el-dialog",
                                    {
                                      attrs: {
                                        width: "30%",
                                        visible: scope.row.dialogImg,
                                      },
                                      on: {
                                        "update:visible": function ($event) {
                                          return _vm.$set(
                                            scope.row,
                                            "dialogImg",
                                            $event
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          width: "100%",
                                          src: scope.row.url,
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3740995302
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.createTemplateAppRequest.appType === "plus" &&
              _vm.typePackage === "ticket"
                ? _c(
                    "div",
                    { staticStyle: { "margin-left": "100px" } },
                    [
                      _c(
                        "el-upload",
                        {
                          ref: "uploadTicket",
                          staticStyle: { "margin-left": "35px", clear: "both" },
                          attrs: {
                            action: "#",
                            "list-type": "picture-card",
                            "auto-upload": false,
                            "file-list": _vm.fileListTicket,
                            "on-change": _vm.handleChangePictureTicket,
                            "on-remove": _vm.handleRemoveTicket,
                            "before-remove": _vm.beforeRemove,
                            "on-preview": _vm.handlePictureCardPreviewTicket,
                            multiple: "",
                          },
                        },
                        [
                          _c(
                            "i",
                            {
                              staticClass: "el-icon-plus",
                              staticStyle: {
                                "font-style": "normal",
                                "white-space": "nowrap",
                              },
                              attrs: { slot: "default" },
                              slot: "default",
                            },
                            [_vm._v("Thêm Ảnh")]
                          ),
                        ]
                      ),
                      _c(
                        "el-dialog",
                        {
                          attrs: {
                            width: "50%",
                            visible: _vm.dialogVisibleTicket,
                          },
                          on: {
                            "update:visible": function ($event) {
                              _vm.dialogVisibleTicket = $event
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              width: "100%",
                              src: _vm.dialogImageUrlTicket,
                              alt: "",
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "tab-infor-button" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "success",
                        size: "medium",
                        loading: _vm.loadingButton,
                        mini: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("createTemplateAppRequest")
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-circle-check" }),
                      _c("span", [_vm._v("Lưu")]),
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger", size: "medium" },
                      on: {
                        click: function ($event) {
                          return _vm.closeDialog()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-circle-close" }),
                      _c("span", [_vm._v("Đóng")]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }