<template>
  <div>
    <div v-if="showSearchIconAppTemplateSystem">
      <div style="margin-bottom: -20px; position: relative; z-index: 1">
        <el-select
            class="button-left-class_search"
            filterable
            placeholder="Chọn loại App"
            v-model="getAllTemplateAppSystemRequest.appType"
            @change="searchHeaderMethodTemplateSystem()"
        >
          <el-option
              v-for="item in appTypeList"
              :key="item.key"
              :value="item.key"
              :label="item.value"
          ></el-option>
        </el-select>
        <el-input
            v-model="getAllTemplateAppSystemRequest.nameTemplate"
            style="width: 300px"
            placeholder="Nhập tên gói"
            @clear="searchHeaderMethodTemplateSystem()"
            clearable
            @keyup.enter.native="searchHeaderMethodTemplateSystem()"
        >
          <el-button
              slot="append"
              icon="el-icon-search"
              @click="searchHeaderMethodTemplateSystem()"
          ></el-button>
        </el-input>
      </div>
    </div>
    <div v-if="showSearchIconAppTemplate">
      <div style="margin-bottom: -20px; position: relative; z-index: 1">
        <el-select
            style="width: 200px"
            class="button-left-class_search"
            filterable
            v-model="getAllTemplateAppRequest.idSchool"
            placeholder="Chọn trường"
            @change="searchHeaderMethodTemplate()"
        >
          <el-option
              v-for="item in schoolList"
              :key="item.id"
              :value="item.id"
              :label="item.schoolName"
          ></el-option>
        </el-select>
        <el-select
            class="button-left-class_search"
            filterable
            placeholder="Chọn loại App"
            v-model="getAllTemplateAppRequest.appType"
            @change="searchHeaderMethodTemplate()"
        >
          <el-option
              v-for="item in appTypeList"
              :key="item.key"
              :value="item.key"
              :label="item.value"
          ></el-option>
        </el-select>
        <el-input
            v-model="getAllTemplateAppRequest.nameTemplate"
            style="width: 300px"
            placeholder="Nhập tên gói"
            @clear="searchHeaderMethodTemplate()"
            clearable
            @keyup.enter.native="searchHeaderMethodTemplate()"
        >
          <el-button
              slot="append"
              icon="el-icon-search"
              @click="searchHeaderMethodTemplate()"
          ></el-button>
        </el-input>
      </div>
    </div>
    <div>
      <el-tabs type="card" @tab-click="handleClickTab" v-model="activeTabName">
        <el-tab-pane label='Hệ thống' name="templateAppSystem">
          <el-table
              ref="multipleTable"
              :empty-text="textTableTemplateSystemAppTab"
              v-loading="loadingDataTemplate"
              :element-loading-text="$tableLoading"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(255,255,255, 0)"
              highlight-current-row
              :data="templateAppSystemList"
              :header-cell-style="tableHeaderColor"
              @selection-change="handleSelectionChangeTemplateSystem"
              :max-height="$tableMaxHeight"
              border
          >
            <el-table-column
                type="selection"
                align="center"
                fixed
                width="80"
            ></el-table-column>
            <el-table-column
                fixed
                type="index"
                label="STT"
                width="80"
                align="center"
            ></el-table-column>
            <el-table-column
                label="Tên gói"
                min-width="200"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.id }} - {{ scope.row.nameTemplate }}</span>
              </template>
            </el-table-column>
            <el-table-column
                label="Mô tả gói"
                min-width="300"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.descriptionTemplate }}</span>
              </template>
            </el-table-column>

            <el-table-column
                label="Phiên bản"
                min-width="150"
                align="center"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.version }}</span>
              </template>
            </el-table-column>
            <el-table-column
                fixed="right"
                label="Tác vụ"
                width="300"
                align="center"
            >
              <template slot-scope="scope">
                <el-button
                    size="mini"
                    type="success"
                    @click="openDetailTemplateSystemAppDialog(scope.row)"
                >Chi tiết
                </el-button>
                <el-button
                    size="mini"
                    type="primary"
                    @click="openUpdateTemplateSystemAppDialog(scope.row)"
                >Sửa
                </el-button
                >
                <el-button
                    size="mini"
                    type="danger"
                    @click="deleteTemplateSystemAppMethod(scope.row)"
                >Xóa
                </el-button
                >
              </template>
            </el-table-column>
          </el-table>

          <el-row style="margin-top: 15px">
            <el-col :span="9" :offset="11">
              <el-pagination
                  background
                  style=" margin-right: 5.2rem;margin-left: auto"
                  @size-change="handleSizeChangeTemplateSystem"
                  @current-change="handleCurrentChangeTemplateSytem"
                  :current-page.sync="currentPagePaginationSystem"
                  :page-sizes="$pageListDefaultNew"
                  :page-size="sizeLimitSystem"
                  layout="prev, pager,sizes, next"
                  :total="sizeLimitSystem * totalPagesSystem"
              ></el-pagination>
            </el-col>
            <el-col :span="2" :offset="2" style="display: inline-flex;justify-content: right">
              <el-dropdown @command="handleActionTemplateSystem">
                <el-button class="button-over" type="success">
                  Tác vụ
                  <i class="el-icon-caret-bottom"/>
                </el-button>
                <el-dropdown-menu>
                  <el-dropdown-item command="addTemplateSystem"
                  >Thêm template
                  </el-dropdown-item
                  >
                  <el-dropdown-item command="deleteManyTemplateSystem" v-if="!showSearchIconAppTemplateSystem"
                  >Xoá template
                  </el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </el-col>
          </el-row>


        </el-tab-pane>
        <!-- Cấu hình gói giao diện -->
        <el-tab-pane label="Nhà trường" name="templateAppTab">
          <el-table
              ref="multipleTable"
              :empty-text="textTableTemplateAppTab"
              v-loading="loadingDataTemplate"
              :element-loading-text="$tableLoading"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(255,255,255, 0)"
              highlight-current-row
              :data="templateAppList"
              :header-cell-style="tableHeaderColor"
              @selection-change="handleSelectionChangeTemplate"
              :max-height="$tableMaxHeight"
              border
          >
            <el-table-column
                type="selection"
                align="center"
                fixed
                width="80"
            ></el-table-column>
            <el-table-column
                fixed
                type="index"
                label="STT"
                width="80"
                align="center"
            ></el-table-column>
            <el-table-column
                label="Tên gói"
                min-width="200"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.id }} - {{ scope.row.nameTemplate }}</span>
              </template>
            </el-table-column>

            <el-table-column
                label="Mô tả gói"
                min-width="300"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.descriptionTemplate }}</span>
              </template>
            </el-table-column>
            <el-table-column
                label="Nguồn"
                width="200"
                align="center"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.typeTemplate }}</span>
              </template>
            </el-table-column>
            <el-table-column
                label="Phiên bản"
                min-width="150"
                align="center"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.version }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Mặc định hệ thống" width="150" align="center">
              <template slot-scope="scope">
                <el-checkbox
                    @change="setDefaultTemplate(scope.row)"
                    v-model="scope.row.defaultTemplate"
                ></el-checkbox>
              </template>
            </el-table-column>

            <el-table-column
                fixed="right"
                label="Tác vụ"
                width="300"
                align="center"
            >
              <template slot-scope="scope">
                <el-button
                    size="mini"
                    type="success"
                    @click="openDetailTemplateAppDialog(scope.row)"
                >Chi tiết
                </el-button>
                <el-button
                    :disabled = "scope.row.typeTemplate === 'system'"
                    size="mini"
                    type="primary"
                    @click="openUpdateTemplateAppDialog(scope.row)"
                >Sửa
                </el-button
                >
                <el-button
                    :disabled = "scope.row.typeTemplate === 'system'"
                    size="mini"
                    type="danger"
                    @click="deleteTemplateAppMethod(scope.row)"
                >Xóa
                </el-button
                >
              </template>
            </el-table-column>
          </el-table>

          <el-row style="margin-top: 15px">
            <el-col :span="9" :offset="11">
              <el-pagination
                  background
                  style=" margin-right: 5.2rem;margin-left: auto"
                  @size-change="handleSizeChangeTemplate"
                  @current-change="handleCurrentChangeTemplate"
                  :current-page.sync="currentPagePagination"
                  :page-sizes="$pageListDefaultNew"
                  :page-size="sizeLimit"
                  layout="prev, pager,sizes, next"
                  :total="sizeLimit * totalPages"
              ></el-pagination>
            </el-col>
            <el-col :span="2" :offset="2" style="display: inline-flex;justify-content: right">
              <el-dropdown @command="handleActionTemplate">
                <el-button class="button-over" type="success">
                  Tác vụ
                  <i class="el-icon-caret-bottom"/>
                </el-button>
                <el-dropdown-menu>
                  <el-dropdown-item command="addTemplate"
                  >Thêm template
                  </el-dropdown-item
                  >
                  <el-dropdown-item command="deleteManyTemplate"
                  >Xoá template
                  </el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </el-col>
          </el-row>


        </el-tab-pane>
      </el-tabs>
    </div>

    <!--  Dialog nhà trường  -->
    <DetailTemplateAppDialog
        :isOpenDetailTemplateAppDialog="showDetailTemplateAppDialog"
        @dialog-close="closeDetailTemplateAppDialog()"
        ref="DetailTemplateAppDialog"
    ></DetailTemplateAppDialog>

    <UpdateTemplateAppDialog
        :isOpenUpdateTemplateAppDialog="showUpdateTemplateAppDialog"
        @dialog-close="closeUpdateTemplateAppDialog()"
        ref="UpdateTemplateAppDialog"
    ></UpdateTemplateAppDialog>

    <AddTemplateAppDialog
        :isOpenAddTemplateAppDialog="showAddTemplateAppDialog"
        @dialog-close="closeAddTemplateAppDialog()"
        ref="AddTemplateAppDialog"
    ></AddTemplateAppDialog>

    <!-- Dialog hệ thống -->
    <AddTemplateAppSystemDialog
        :isOpenAddTemplateAppDialog="showAddTemplateAppDialogSystem"
        @dialog-close="closeAddTemplateAppDialogSystem()"
        ref="AddTemplateAppSystemDialog"
    />

    <DetailTemplateAppSystemDialog
        :isOpenDetailTemplateAppDialog="showDetailTemplateSystemAppDialog"
        @dialog-close="closeDetailTemplateSysAppDialog()"
        ref="DetailTemplateAppSystemDialog"
    />

    <UpdateTemplateSystemAppDialog
        :isOpenUpdateTemplateAppDialog="showUpdateTemplateSystemAppDialog"
        @dialog-close="closeUpdateTemplateSystemAppDialog()"
        ref="UpdateTemplateSystemAppDialog"
    />

  </div>
</template>


<script>
import SystemConfigService from "@/services/AdminService/SystemConfigService";
import DetailTemplateAppDialog from "@/views/templateApp/DetailTemplateAppDialog.vue";
import AddTemplateAppDialog from "@/views/templateApp/AddTemplateAppDialog.vue";
import {mapActions, mapState} from "vuex";
import UpdateTemplateAppDialog from "@/views/templateApp/UpdateTemplateAppDialog.vue";
import AddTemplateAppSystemDialog from "@/views/templateApp/AddTemplateAppSystemDialog.vue";
import DetailTemplateAppSystemDialog from "@/views/templateApp/DetailTemplateAppSystemDialog.vue";
import UpdateTemplateSystemAppDialog from "@/views/templateApp/UpdateTemplateSystemAppDialog.vue";


export default {
  components: {
    UpdateTemplateSystemAppDialog,
    DetailTemplateAppSystemDialog,
    AddTemplateAppSystemDialog,
    UpdateTemplateAppDialog,
    AddTemplateAppDialog,
    DetailTemplateAppDialog,
  },
  created() {
    this.$store.dispatch('schoolStore/fetchDataAgentList');
  },
  computed: {
    ...mapState('schoolStore', ['agentList']),
    ...mapState('schoolStore', ['schoolList'])
  },
  watch: {
    agentList: {
      immediate: true,
      handler(newVal) {
        if (newVal.length > 0) {
          this.getIdAgent();
        }
      },
    },
  },
  data() {
    return {
      sizeLimit: 10,
      sizeLimitSystem: 10,
      totalPages: 0,
      totalPagesSystem: 0,
      currentPagePagination: 1,
      currentPagePaginationSystem: 1,
      activeTabName: "templateAppSystem",
      loadingData: true,
      loaddingButton: false,
      showUpdateDialog: false,
      showDetailTemplateAppDialog: false,
      showUpdateTemplateAppDialog: false,
      showAddTemplateAppDialog: false,
      showAddTemplateAppDialogSystem: false,
      showDetailTemplateSystemAppDialog: false,
      showUpdateTemplateSystemAppDialog: false,
      loaddingButtonClass: false,
      idAgent: "",
      layout: "sizes, prev, pager, next",
      getAllTemplateAppRequest: {
        idSchool: 1,
        appType: "plus",
        nameTemplate: "",
        pageNumber: 1,
        pageTotal: this.$pageTotalDefault,
        maxPageItem: 10,
      },
      getAllTemplateAppSystemRequest: {
        appType: "plus",
        nameTemplate: "",
        pageNumber: 1,
        pageTotal: this.$pageTotalDefault,
        maxPageItem: 10,
      },
      textTableTemplateAppTab: "",
      textTableTemplateSystemAppTab: "",
      templateAppList: [],
      templateAppSystemList: [],
      showSearchIconAppTemplate: false,
      showSearchIconAppTemplateSystem: true,
      appTypeList: [
        {key: "plus", value: "App Plus"},
        {key: "teacher", value: "App Teacher"},
        {key: "parent", value: "App Onekids"},
      ],
      multipleSelectionTemplate: [],
      multipleSelectionTemplateSystem: [],
      loadingDataTemplate: true,
    };
  },
  methods: {
    ...mapActions('schoolStore', ["fetchDataSchoolList"]),
    tableHeaderColor() {
      return "background-color: #78a5e7;color: #fff;font-weight: bold;";
    },
    tableRowStyleDetail({row}) {
      if (
          row.absentStatus == "Nghỉ có phép" ||
          row.absentStatus == "Nghỉ không phép"
      ) {
        return "color: red";
      } else if (row.absentStatus == "Chưa điểm danh") {
        return "color: #409EFF";
      }
    },

    handleClickTab(tab) {
      let tabNameClick = tab.name;
      if (tabNameClick === "templateAppTab") {
        this.showSearchIconAppTemplate = true;
        this.showSearchIconAppTemplateSystem = false;
        this.getAllDataForTemplateAppTab()
      }else if (tabNameClick === 'templateAppSystem'){
        this.showSearchIconAppTemplateSystem = true;
        this.showSearchIconAppTemplate = false;
        this.getAllDataForTemplateAppTabSystem()
      }

    },

    /// Giao diện App

    openDetailTemplateAppDialog(row) {
      this.showDetailTemplateAppDialog = true;
      const selectedSchool = this.schoolList.find(item => item.id === row.idSchool);
      let nameSchool = selectedSchool.schoolName;
      this.$refs.DetailTemplateAppDialog.getDataInitialDetailTemplateAppDialog(nameSchool, row);
    },

    openDetailTemplateSystemAppDialog(row) {
      this.showDetailTemplateSystemAppDialog = true;
      this.$refs.DetailTemplateAppSystemDialog.getDataInitialDetailTemplateAppDialog(row);
    },
    closeDetailTemplateAppDialog() {
      this.showDetailTemplateAppDialog = false;
    },
    closeDetailTemplateSysAppDialog() {
      this.showDetailTemplateSystemAppDialog = false;
    },

    openAddTemplateAppDialog() {
      this.showAddTemplateAppDialog = true;
    },
    openAddTemplateAppDialogSystem() {
      this.showAddTemplateAppDialogSystem = true;
    },
    closeAddTemplateAppDialog() {
      this.showAddTemplateAppDialog = false;
      this.getAllDataForTemplateAppTab();
    },
    closeAddTemplateAppDialogSystem() {
      this.showAddTemplateAppDialogSystem = false;
      this.getAllDataForTemplateAppTabSystem();
    },

    openUpdateTemplateAppDialog(row) {
      this.showUpdateTemplateAppDialog = true;
      const selectedSchool = this.schoolList.find(item => item.id === row.idSchool);
      let nameSchool = selectedSchool.schoolName;
      this.$refs.UpdateTemplateAppDialog.getDataInitialUpdateTemplateDialog(nameSchool, row);
    },
    async openUpdateTemplateSystemAppDialog(row) {
      await this.$refs.UpdateTemplateSystemAppDialog.getDataInitialUpdateTemplateDialog(row);
      this.showUpdateTemplateSystemAppDialog= true;
    },
    closeUpdateTemplateAppDialog() {
      this.showUpdateTemplateAppDialog = false;
      this.getAllDataForTemplateAppTab();
    },
    closeUpdateTemplateSystemAppDialog() {
      this.showUpdateTemplateSystemAppDialog = false;
      this.getAllDataForTemplateAppTabSystem();
    },

    handleActionTemplate(command) {
      if (command === "addTemplate") {
        this.openAddTemplateAppDialog();
      } else if (command === "deleteManyTemplate") {
        this.deleteOneOrManyTemplate()
      }
    },
    handleActionTemplateSystem(command) {
      if (command === "addTemplateSystem") {
        this.openAddTemplateAppDialogSystem();
      } else if (command === "deleteManyTemplateSystem") {
        this.deleteOneOrManyTemplate()
      }
    },
    handleSelectionChangeTemplate(val) {
      this.multipleSelectionTemplate = val;
    },
    handleSelectionChangeTemplateSystem(val) {
      this.multipleSelectionTemplateSystem = val;
    },
    getAllDataForTemplateAppTab() {
      this.templateAppList = [];
      this.loadingDataTemplate = true;
      let idSchool = this.getAllTemplateAppRequest.idSchool;
      let appType = this.getAllTemplateAppRequest.appType;
      let nameTemplate = this.getAllTemplateAppRequest.nameTemplate;
      let pageNumber = this.getAllTemplateAppRequest.pageNumber;
      let maxPageItem = this.getAllTemplateAppRequest.maxPageItem;
      SystemConfigService.getListTemplate(idSchool, appType, nameTemplate, pageNumber, maxPageItem)
          .then((resp) => {
            this.templateAppList = resp.data.data.listTemplate;
            this.loadingDataTemplate = false;
            this.totalPages = Math.floor(resp.data.data.total / this.sizeLimit);
            if ((resp.data.data.total % this.sizeLimit) !== 0) {
              this.totalPages = this.totalPages + 1
            }
          })
          .catch((err) => {
            this.pageTotal = 1;
            this.templateAppList = [];
            this.loadingDataTemplate = false;
            console.log(err);
          })
          .finally(() => {
            if (this.templateAppList.length === 0) {
              this.textTableTemplateAppTab = this.$tableEmpty;
            }
            this.loadingDataTemplate = false;
          });
    },

    getAllDataForTemplateAppTabSystem() {
      this.templateAppSystemList = [];
      this.loadingDataTemplate = true;
      let appType = this.getAllTemplateAppSystemRequest.appType;
      let nameTemplate = this.getAllTemplateAppSystemRequest.nameTemplate;
      let pageNumber = this.getAllTemplateAppSystemRequest.pageNumber;
      let maxPageItem = this.getAllTemplateAppSystemRequest.maxPageItem;
      SystemConfigService.getListTemplateSystem(appType, nameTemplate, pageNumber, maxPageItem)
          .then((resp) => {
            this.templateAppSystemList = resp.data.data.listTemplateSystem;
            this.loadingDataTemplate = false;
            this.totalPagesSystem = Math.floor(resp.data.data.total / this.sizeLimit);
            if ((resp.data.data.total % this.sizeLimitSystem) !== 0) {
              this.totalPagesSystem = this.totalPagesSystem + 1
            }
          })
          .catch((err) => {
            this.pageTotal = 1;
            this.templateAppSystemList = [];
            this.loadingDataTemplate = false;
            console.log(err);
          })
          .finally(() => {
            if (this.templateAppSystemList.length === 0) {
              this.textTableTemplateSystemAppTab = this.$tableEmpty;
            }
            this.loadingDataTemplate = false;
          });
    },

    handleSizeChangeTemplate(value) {
      this.sizeLimit = value;
      this.getAllTemplateAppRequest.maxPageItem = value;
      this.getAllDataForTemplateAppTab();
    },
    handleSizeChangeTemplateSystem(value) {
      this.sizeLimitSystem = value;
      this.getAllTemplateAppSystemRequest.maxPageItem = value;
      this.getAllDataForTemplateAppTabSystem();
    },
    handleCurrentChangeTemplate(value) {
      this.currentPagePagination = value
      this.getAllTemplateAppRequest.pageNumber = value;
      this.getAllDataForTemplateAppTab();
    },
    handleCurrentChangeTemplateSytem(value) {
      this.currentPagePaginationSystem = value
      this.getAllTemplateAppSystemRequest.pageNumber = value;
      this.getAllDataForTemplateAppTabSystem();
    },
    searchHeaderMethodTemplate() {
      this.pageNumber = 1;
      this.getAllDataForTemplateAppTab();
    },

    searchHeaderMethodTemplateSystem() {
      this.pageNumber = 1;
      this.getAllDataForTemplateAppTabSystem();
    },


    /**
     * danh sách đại lý
     */
    getIdAgent() {
      this.idAgent = this.agentList[0].id;
      this.fetchDataSchoolList(this.idAgent);
      this.getAllDataForTemplateAppTab()
      this.getAllDataForTemplateAppTabSystem()
    },

    /**
     * Xoá 1 template
     */

    deleteTemplateAppMethod(row) {
      let idTemplate = row.id
      this.$confirm('Xác nhận xóa template ?', 'Cảnh báo', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        SystemConfigService.deleteOneTemplate(idTemplate).then((res) => {
          this.$message({
            type: 'success',
            message: res.data.message
          });
          this.getAllDataForTemplateAppTab()
          this.closeDialog()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Hủy thao tác'
        });
      });
    },
    deleteTemplateSystemAppMethod(row) {
      let idTemplate = row.id
      this.$confirm('Xác nhận xóa template ?', 'Cảnh báo', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        SystemConfigService.deleteOneTemplateSystem(idTemplate).then((res) => {
          this.$message({
            type: 'success',
            message: res.data.message
          });
          this.getAllDataForTemplateAppTabSystem()
          this.closeDialog()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Hủy thao tác'
        });
      });
    },

    /**
     * Xoá 1 hoặc nhiều Template
     */
    deleteOneOrManyTemplate() {
      let idTemplateList = this.multipleSelectionTemplate.map(x => x.id);
      this.checkSelectBeforeHandleFunction(idTemplateList);
      this.$confirm('Xác nhận xóa các template đã chọn ?', 'Cảnh báo', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        SystemConfigService.deleteOneOrManyTemplate(idTemplateList).then((res) => {
          this.$message({
            type: 'success',
            message: res.data.message
          });
          this.getAllDataForTemplateAppTab()
          this.closeDialog()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Hủy thao tác'
        });
      });
    },

    checkSelectBeforeHandleFunction() {
      let selectRowList = this.multipleSelectionTemplate.map((x) => x.id);
      if (selectRowList.length == 0) {
        this.$message({
          message: "Bạn chưa chọn gói nào",
          type: "error",
        });
        throw null;
      }
    },

    setDefaultTemplate(row) {
      let idTemplate = row.id
      this.loadingDataTemplate = true;
      SystemConfigService.setDefaultTemplate(idTemplate)
          .then((resp) => {
            this.$message({
              message: resp.data.message,
              type: "success",
            });
            this.getAllDataForTemplateAppTab()
          })
          .catch((err) => {
            this.$message({
              message: err.response.data.message,
              type: "error",
            });
          })
          .finally(() => {
            setTimeout(() => {
              this.loadingDataTemplate = false;
            }, 500);
          });
    },

  },

};
</script>


<style lang="scss" scoped>
/deep/ .el-tabs__nav {
  background: #a0a19ce0;
}

/deep/ .el-tabs__item {
  color: white;
}

/deep/ .el-tabs__item.is-active {
  color: white;
  background: #78a5e7;
}

/deep/ .el-form-item__label {
  font-family: Arial, Helvetica, sans-serif;
}

/deep/ .el-tabs__nav-scroll {
  float: right;
}

/deep/ .el-tabs__header {
  margin-bottom: 0;
}

/deep/ .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  border-bottom-color: #78a5e7;
}

/deep/ .el-tabs--card > .el-tabs__header {
  border-bottom: none;
}

/deep/ .el-table td,
/deep/ .el-table th {
  padding: 10px 0;
}

/deep/ .el-table .cell {
  line-height: 15px;
}

/deep/ .el-table th > .cell {
  font-size: 13px;
}

/deep/ .el-checkbox__inner {
  border: 1px solid gray;
}

/deep/ .el-table .cell {
  white-space: pre-line;
  line-height: 20px;
  word-break: break-word;
}

.input-common {
  margin-right: 5px;
}

.button-bottom {
  border-radius: 0;
  margin-left: 3px;
}

.button-click {
  float: right;
  margin: 20px 0 40px 0;
}

.el-dropdown-menu {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 0;
  margin: 3px 0;
  background-color: #5fb44a;
  border: 1px solid #ebeef5;
  border-radius: 0;
  box-shadow: 0 2px 12px 0 #0000001a;
}

.el-dropdown-menu li {
  color: white;
  border-top: 1px solid white;
  font-family: Arial, Helvetica, sans-serif;
}

.over-table {
  width: 97%;
  margin: 0 auto;
}

.table-data {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ebeef5;

  // display: inline-block;
}

.wrapper-table {
  height: 600px;
  overflow-y: scroll;
  border-bottom: 1px solid #ebeef5;
}

// .table-header {
//   position: sticky;
// }
// table{

// }
thead {
  background: #78a5e7;
  color: white;
  font-weight: bold;
  // display: block;
  // width: 90%;
}

tbody {
  color: #606266;
  font-size: 14px;
  overflow: auto;
  // height: 600px;
  font-family: Arial, Helvetica, sans-serif;
  // display: block;
  // width: 90%;
}

td {
  padding: 12px;
}

.table-index {
  width: 10px;
  text-align: center;
}

.table-item-name {
  width: 200px;
  text-align: center;
}

.table-action {
  width: 200px;
  text-align: center;
}

.color-text-reply {
  color: #4177e2;
}

.input-data {
  width: 60%;
}

.disable-row {
  background: #e4e7ed;
}

.button-left-class_search {
  margin-right: 5px;
  width: 150px;
}
</style>