var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.isOpenUpdateTemplateAppDialog,
            width: "90%",
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            top: "3vh",
            title: "Cập nhật gói giao diện App",
            border: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isOpenUpdateTemplateAppDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "updateTemplateAppRequest",
              attrs: {
                "label-width": "140px",
                model: _vm.updateTemplateAppRequest,
                "label-position": "left",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 50 } },
                [
                  _c("el-col", { attrs: { span: 10 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "Tên template",
                              prop: "nameTemplate",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "Nhập tên template" },
                              model: {
                                value:
                                  _vm.updateTemplateAppRequest.nameTemplate,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.updateTemplateAppRequest,
                                    "nameTemplate",
                                    $$v
                                  )
                                },
                                expression:
                                  "updateTemplateAppRequest.nameTemplate",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 14 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "Mô tả",
                              prop: "descriptionTemplate",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "Nhập mô tả" },
                              model: {
                                value:
                                  _vm.updateTemplateAppRequest
                                    .descriptionTemplate,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.updateTemplateAppRequest,
                                    "descriptionTemplate",
                                    $$v
                                  )
                                },
                                expression:
                                  "updateTemplateAppRequest.descriptionTemplate",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Phiên bản", prop: "version" } },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.updateTemplateAppRequest.version)
                            ),
                          ]),
                          _c("el-input-number", {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { min: 0 },
                            model: {
                              value: _vm.version,
                              callback: function ($$v) {
                                _vm.version = $$v
                              },
                              expression: "version",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "20px" },
                            attrs: { label: "Loại App" },
                          },
                          [
                            _c("h4", { staticClass: "app_name" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.updateTemplateAppRequest.appType) +
                                  "\n              "
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 13 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c("el-form-item", { attrs: { label: "Tên trường" } }, [
                          _c("h4", { staticClass: "school_name" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.updateTemplateAppRequest.nameSchool
                                ) +
                                "\n              "
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticStyle: { "margin-top": "12px" },
                          on: { change: _vm.handleChangeTypeTicket },
                          model: {
                            value: _vm.typePackage,
                            callback: function ($$v) {
                              _vm.typePackage = $$v
                            },
                            expression: "typePackage",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "template" } }, [
                            _vm._v("Giao diện"),
                          ]),
                          _vm.updateTemplateAppRequest.appType === "plus"
                            ? _c(
                                "el-radio",
                                {
                                  staticStyle: { "margin-left": "50px" },
                                  attrs: { label: "ticket" },
                                },
                                [
                                  _vm._v(
                                    "\n              Phiếu bé ngoan\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.typePackage === "ticket" &&
              _vm.updateTemplateAppRequest.appType === "plus"
                ? _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 9 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Màn hình" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: {
                                    width: "228px",
                                    display: "block",
                                  },
                                  attrs: { placeholder: "Chọn màn hình" },
                                  on: { change: _vm.handleChangeDisplayTicket },
                                  model: {
                                    value: _vm.displayTicket,
                                    callback: function ($$v) {
                                      _vm.displayTicket = $$v
                                    },
                                    expression: "displayTicket",
                                  },
                                },
                                _vm._l(_vm.listDisplayTicket, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    staticStyle: { width: "200px" },
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: {
                            "margin-left": "36px",
                            border: "1px solid #ccc",
                            padding: "10px",
                          },
                          attrs: { span: 14 },
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { "overflow-x": "auto" } },
                            [
                              _c("el-row", [
                                _c("span", [
                                  _c(
                                    "b",
                                    { staticStyle: { "font-size": "17px" } },
                                    [_vm._v("Thêm ảnh")]
                                  ),
                                ]),
                              ]),
                              _c("hr"),
                              _vm.typePackage === "ticket"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-upload",
                                        {
                                          ref: "uploadTicket",
                                          staticStyle: {
                                            "margin-left": "35px",
                                            clear: "both",
                                          },
                                          attrs: {
                                            action: "#",
                                            "list-type": "picture-card",
                                            "auto-upload": false,
                                            "file-list": _vm.fileListTicket,
                                            "on-change":
                                              _vm.handleChangePictureTicket,
                                            "on-remove": _vm.handleRemoveTicket,
                                            "before-remove": _vm.beforeRemove,
                                            "on-preview":
                                              _vm.handlePictureCardPreviewTicket,
                                            multiple: "",
                                          },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass: "el-icon-plus",
                                              staticStyle: {
                                                "font-style": "normal",
                                                "white-space": "nowrap",
                                              },
                                              attrs: { slot: "default" },
                                              slot: "default",
                                            },
                                            [_vm._v("Thêm Ảnh")]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "el-dialog",
                                        {
                                          attrs: {
                                            width: "50%",
                                            visible: _vm.dialogVisibleTicket,
                                          },
                                          on: {
                                            "update:visible": function (
                                              $event
                                            ) {
                                              _vm.dialogVisibleTicket = $event
                                            },
                                          },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              width: "100%",
                                              src: _vm.dialogImageUrlTicket,
                                              alt: "",
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          [
                            _c("el-row", [
                              _c("span", [
                                _c(
                                  "b",
                                  { staticStyle: { "font-size": "17px" } },
                                  [
                                    _vm._v(
                                      "Danh sách: " + _vm._s(_vm.titleTicket)
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("hr"),
                            _c(
                              "el-row",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-wrap": "wrap",
                                  width: "100%",
                                  "margin-top": "10px",
                                  border: "1px solid rgb(96 99 102 / 19%)",
                                  "max-height": "600px",
                                  "padding-left": "50px",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      width: "100%",
                                      "overflow-x": "hidden",
                                      "max-height": "600px",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          transition: "all 0.5s linear",
                                        },
                                        style: {
                                          transform: `translateX(-${
                                            _vm.currentSlideIndex *
                                            (100 /
                                              (this.listTicketUpdate.length /
                                                this.itemsToShow))
                                          }%)`,
                                        },
                                      },
                                      _vm._l(
                                        _vm.listTicketUpdate,
                                        function (item) {
                                          return _c(
                                            "div",
                                            {
                                              key: item.id,
                                              staticStyle: {
                                                flex: "0 0 223px",
                                                margin: "30px",
                                                width: "223px",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "inline-block-div",
                                                },
                                                [
                                                  _c("el-image", {
                                                    staticStyle: {
                                                      width: "200px",
                                                      height: "200px",
                                                    },
                                                    attrs: {
                                                      src: item.urlPicture,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleShowDialogImage(
                                                          item.urlPicture
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "el-upload-list__item-actions",
                                                    },
                                                    [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          staticClass:
                                                            "el-upload-list__item-preview",
                                                          staticStyle: {
                                                            float: "left",
                                                            height: "40px",
                                                            width: "200px",
                                                          },
                                                          attrs: {
                                                            type: "success",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.deletePicture(
                                                                item.id
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-delete-solid",
                                                            staticStyle: {
                                                              color: "white",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                                _vm.listTicketUpdate.length > 0
                                  ? _c(
                                      "div",
                                      { staticClass: "container_btn_nav" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "btn_nav btn-next",
                                            staticStyle: {
                                              right: "-18px",
                                              top: "50%",
                                              position: "absolute",
                                            },
                                            style: {
                                              cursor:
                                                _vm.currentSlideIndex ===
                                                _vm.maxIndex
                                                  ? "not-allowed"
                                                  : "pointer",
                                            },
                                            on: { click: _vm.nextSlide },
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                staticClass:
                                                  "lucide lucide-chevron-right",
                                                attrs: {
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg",
                                                  width: "24",
                                                  height: "24",
                                                  viewBox: "0 0 24 24",
                                                  fill: "none",
                                                  stroke: "currentColor",
                                                  "stroke-width": "2",
                                                  "stroke-linecap": "round",
                                                  "stroke-linejoin": "round",
                                                },
                                              },
                                              [
                                                _c("path", {
                                                  attrs: { d: "m9 18 6-6-6-6" },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "btn_nav btn-prev",
                                            staticStyle: {
                                              left: "-18px",
                                              top: "50%",
                                              position: "absolute",
                                            },
                                            style: {
                                              cursor:
                                                _vm.currentSlideIndex === 0
                                                  ? "not-allowed"
                                                  : "pointer",
                                            },
                                            on: { click: _vm.prevSlide },
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                staticClass:
                                                  "lucide lucide-chevron-left",
                                                attrs: {
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg",
                                                  width: "24",
                                                  height: "24",
                                                  viewBox: "0 0 24 24",
                                                  fill: "none",
                                                  stroke: "currentColor",
                                                  "stroke-width": "2",
                                                  "stroke-linecap": "round",
                                                  "stroke-linejoin": "round",
                                                },
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    d: "m15 18-6-6 6-6",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-dialog",
                                  {
                                    attrs: {
                                      modal: false,
                                      width: "40%",
                                      visible:
                                        _vm.showDialogImage.idVisibleImage,
                                    },
                                    on: {
                                      "update:visible": function ($event) {
                                        return _vm.$set(
                                          _vm.showDialogImage,
                                          "idVisibleImage",
                                          $event
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        height: "400px",
                                        "object-fit": "contain",
                                      },
                                      attrs: {
                                        width: "100%",
                                        src: _vm.showDialogImage.urlImage,
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.updateTemplateAppRequest.appType === "plus" &&
              _vm.typePackage === "template"
                ? _c(
                    "el-table",
                    {
                      ref: "multipleTable",
                      attrs: {
                        "element-loading-spinner": "el-icon-loading",
                        "element-loading-background": "rgba(255,255,255, 0)",
                        "highlight-current-row": "",
                        data: _vm.plusScreenList,
                        "header-cell-style": _vm.tableHeaderColor,
                        "max-height": 500,
                        border: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          label: "STT",
                          width: "80",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Màn hình",
                          align: "center",
                          width: "200px",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [_vm._v(_vm._s(scope.row.value))]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1320824237
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "Ảnh hiện tại", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    [
                                      _c("el-image", {
                                        staticClass: "el-image-opacity",
                                        staticStyle: {
                                          width: "150px",
                                          height: "150px",
                                        },
                                        attrs: { src: scope.row.urlKeep },
                                        on: {
                                          click: function ($event) {
                                            return _vm.showTemplateDefault(
                                              scope.row.urlKeep
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2151529119
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "Chọn ảnh mới" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-upload",
                                    {
                                      ref: "upload",
                                      attrs: {
                                        action: "/api/upload/",
                                        "list-type": "picture-card",
                                        "auto-upload": false,
                                        "file-list": _vm.getFileList(
                                          scope.row.file
                                        ),
                                        "on-change": (file) =>
                                          _vm.handleChangePicture(
                                            scope.row,
                                            file
                                          ),
                                        "on-remove": (file) =>
                                          _vm.handleRemove(scope.row, file),
                                        "before-remove": (file) =>
                                          _vm.beforeRemove(scope.row, file),
                                        accept: "image/jpeg,image/png",
                                        disabled: scope.row.showFile === false,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "file",
                                            fn: function ({ file }) {
                                              return _c("div", {}, [
                                                _c(
                                                  "span",
                                                  { staticClass: "tag_image" },
                                                  [_vm._v("New")]
                                                ),
                                                _c("img", {
                                                  staticClass:
                                                    "el-upload-list__item-thumbnail",
                                                  attrs: {
                                                    src: scope.row.url,
                                                    alt: "",
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "el-upload-list__item-actions",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "el-upload-list__item-preview",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handlePictureCardPreview(
                                                              scope.row,
                                                              file
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-zoom-in",
                                                        }),
                                                      ]
                                                    ),
                                                    !scope.row.disabled
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "el-upload-list__item-delete",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleRemove(
                                                                  scope.row,
                                                                  file
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "el-icon-delete",
                                                            }),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ])
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      scope.row.showFile === true
                                        ? _c("div", [
                                            _c(
                                              "i",
                                              {
                                                staticClass: "el-icon-plus",
                                                staticStyle: {
                                                  "font-style": "normal",
                                                },
                                                attrs: { slot: "default" },
                                                slot: "default",
                                              },
                                              [_vm._v("Thêm ảnh")]
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c(
                                    "el-dialog",
                                    {
                                      attrs: {
                                        width: "30%",
                                        visible: scope.row.dialogImg,
                                      },
                                      on: {
                                        "update:visible": function ($event) {
                                          return _vm.$set(
                                            scope.row,
                                            "dialogImg",
                                            $event
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          width: "100%",
                                          src: scope.row.url,
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2588653148
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Tác vụ",
                          width: "100px",
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        disabled:
                                          scope.row.id === null ||
                                          scope.row.urlKeep === "",
                                        size: "mini",
                                        type: "danger",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteTemplateSchool(
                                            scope.row.id
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Xóa\n            ")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          139570753
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.updateTemplateAppRequest.appType === "teacher" &&
              _vm.typePackage === "template"
                ? _c(
                    "el-table",
                    {
                      ref: "multipleTable",
                      attrs: {
                        "element-loading-spinner": "el-icon-loading",
                        "element-loading-background": "rgba(255,255,255, 0)",
                        "highlight-current-row": "",
                        data: _vm.teacherScreenList,
                        "header-cell-style": _vm.tableHeaderColor,
                        "max-height": 500,
                        border: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          label: "STT",
                          width: "80",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Màn hình",
                          align: "center",
                          width: "200px",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [_vm._v(_vm._s(scope.row.value))]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1320824237
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "Ảnh hiện tại", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    [
                                      _c("el-image", {
                                        staticClass: "el-image-opacity",
                                        staticStyle: {
                                          width: "150px",
                                          height: "150px",
                                        },
                                        attrs: { src: scope.row.urlKeep },
                                        on: {
                                          click: function ($event) {
                                            return _vm.showTemplateDefault(
                                              scope.row.urlKeep
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2151529119
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "Chọn ảnh mới" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-upload",
                                    {
                                      ref: "upload",
                                      attrs: {
                                        action: "/api/upload/",
                                        "list-type": "picture-card",
                                        "auto-upload": false,
                                        "file-list": _vm.getFileList(
                                          scope.row.file
                                        ),
                                        "on-change": (file) =>
                                          _vm.handleChangePicture(
                                            scope.row,
                                            file
                                          ),
                                        "on-remove": (file) =>
                                          _vm.handleRemove(scope.row, file),
                                        "before-remove": (file) =>
                                          _vm.beforeRemove(scope.row, file),
                                        accept: "image/jpeg,image/png",
                                        disabled: scope.row.showFile === false,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "file",
                                            fn: function ({ file }) {
                                              return _c("div", {}, [
                                                _c(
                                                  "span",
                                                  { staticClass: "tag_image" },
                                                  [_vm._v("New")]
                                                ),
                                                _c("img", {
                                                  staticClass:
                                                    "el-upload-list__item-thumbnail",
                                                  attrs: {
                                                    src: scope.row.url,
                                                    alt: "",
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "el-upload-list__item-actions",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "el-upload-list__item-preview",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handlePictureCardPreview(
                                                              scope.row,
                                                              file
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-zoom-in",
                                                        }),
                                                      ]
                                                    ),
                                                    !scope.row.disabled
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "el-upload-list__item-delete",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleRemove(
                                                                  scope.row,
                                                                  file
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "el-icon-delete",
                                                            }),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ])
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      scope.row.showFile === true
                                        ? _c("div", [
                                            _c(
                                              "i",
                                              {
                                                staticClass: "el-icon-plus",
                                                staticStyle: {
                                                  "font-style": "normal",
                                                },
                                                attrs: { slot: "default" },
                                                slot: "default",
                                              },
                                              [_vm._v("Thêm ảnh")]
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c(
                                    "el-dialog",
                                    {
                                      attrs: {
                                        width: "30%",
                                        visible: scope.row.dialogImg,
                                      },
                                      on: {
                                        "update:visible": function ($event) {
                                          return _vm.$set(
                                            scope.row,
                                            "dialogImg",
                                            $event
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          width: "100%",
                                          src: scope.row.url,
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2588653148
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Tác vụ",
                          width: "100px",
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        disabled:
                                          scope.row.id === null ||
                                          scope.row.urlKeep === "",
                                        size: "mini",
                                        type: "danger",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteTemplateSchool(
                                            scope.row.id
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Xóa\n            ")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          139570753
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.updateTemplateAppRequest.appType === "parent" &&
              _vm.typePackage === "template"
                ? _c(
                    "el-table",
                    {
                      ref: "multipleTable",
                      attrs: {
                        "element-loading-spinner": "el-icon-loading",
                        "element-loading-background": "rgba(255,255,255, 0)",
                        "highlight-current-row": "",
                        data: _vm.parentScreenList,
                        "header-cell-style": _vm.tableHeaderColor,
                        "max-height": 500,
                        border: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          label: "STT",
                          width: "80",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Màn hình",
                          align: "center",
                          width: "200px",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [_vm._v(_vm._s(scope.row.value))]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1320824237
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "Ảnh hiện tại", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    [
                                      _c("el-image", {
                                        staticClass: "el-image-opacity",
                                        staticStyle: {
                                          width: "150px",
                                          height: "150px",
                                        },
                                        attrs: { src: scope.row.urlKeep },
                                        on: {
                                          click: function ($event) {
                                            return _vm.showTemplateDefault(
                                              scope.row.urlKeep
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2151529119
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "Chọn ảnh mới" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-upload",
                                    {
                                      ref: "upload",
                                      attrs: {
                                        action: "/api/upload/",
                                        "list-type": "picture-card",
                                        "auto-upload": false,
                                        "file-list": _vm.getFileList(
                                          scope.row.file
                                        ),
                                        "on-change": (file) =>
                                          _vm.handleChangePicture(
                                            scope.row,
                                            file
                                          ),
                                        "on-remove": (file) =>
                                          _vm.handleRemove(scope.row, file),
                                        "before-remove": (file) =>
                                          _vm.beforeRemove(scope.row, file),
                                        accept: "image/jpeg,image/png",
                                        disabled: scope.row.showFile === false,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "file",
                                            fn: function ({ file }) {
                                              return _c("div", {}, [
                                                _c(
                                                  "span",
                                                  { staticClass: "tag_image" },
                                                  [_vm._v("New")]
                                                ),
                                                _c("img", {
                                                  staticClass:
                                                    "el-upload-list__item-thumbnail",
                                                  attrs: {
                                                    src: scope.row.url,
                                                    alt: "",
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "el-upload-list__item-actions",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "el-upload-list__item-preview",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handlePictureCardPreview(
                                                              scope.row,
                                                              file
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-zoom-in",
                                                        }),
                                                      ]
                                                    ),
                                                    !scope.row.disabled
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "el-upload-list__item-delete",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleRemove(
                                                                  scope.row,
                                                                  file
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "el-icon-delete",
                                                            }),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ])
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      scope.row.showFile === true
                                        ? _c("div", [
                                            _c(
                                              "i",
                                              {
                                                staticClass: "el-icon-plus",
                                                staticStyle: {
                                                  "font-style": "normal",
                                                },
                                                attrs: { slot: "default" },
                                                slot: "default",
                                              },
                                              [_vm._v("Thêm ảnh")]
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c(
                                    "el-dialog",
                                    {
                                      attrs: {
                                        width: "30%",
                                        visible: scope.row.dialogImg,
                                      },
                                      on: {
                                        "update:visible": function ($event) {
                                          return _vm.$set(
                                            scope.row,
                                            "dialogImg",
                                            $event
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          width: "100%",
                                          src: scope.row.url,
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2588653148
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Tác vụ",
                          width: "100px",
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        disabled:
                                          scope.row.id === null ||
                                          scope.row.urlKeep === "",
                                        size: "mini",
                                        type: "danger",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteTemplateSchool(
                                            scope.row.id
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Xóa\n            ")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          139570753
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "tab-infor-button" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "success",
                        size: "medium",
                        loading: _vm.loadingButton,
                        mini: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("updateTemplateAppRequest")
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-circle-check" }),
                      _c("span", [_vm._v("Lưu")]),
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger", size: "medium" },
                      on: {
                        click: function ($event) {
                          return _vm.closeDialog()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-circle-close" }),
                      _c("span", [_vm._v("Đóng")]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { width: "30%", visible: _vm.dialogVisibleTemplateDefault },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisibleTemplateDefault = $event
            },
          },
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.urlTemplateDefault, alt: "" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }