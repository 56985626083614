<template>
  <div>
    <el-dialog
        :visible.sync="isOpenAddTemplateAppDialog"
        width="800px"
        :before-close="closeDialog"
        :close-on-click-modal="false"
        top="3vh"
        title="Thêm gói giao diện App"
        border
    >
      <el-form
          label-width="140px"
          :model="createTemplateAppRequest"
          label-position="left"
          :rules="rules"
          ref="createTemplateAppRequest"
      >
        <el-row>
          <el-col :span="15">
            <div class="grid-content">
              <el-form-item label="Tên template" prop="nameTemplate">
                <el-input
                    v-model="createTemplateAppRequest.nameTemplate"
                    placeholder="Nhập tên template"
                ></el-input>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="15">
            <el-form-item label="Mô tả" prop="descriptionTemplate">
              <el-input
                  v-model="createTemplateAppRequest.descriptionTemplate"
                  placeholder="Nhập mô tả"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="15">
            <div class="grid-content">
              <el-form-item prop="appType" label="Loại app">
                <el-radio-group v-model="createTemplateAppRequest.appType">
                  <el-radio label="plus">Plus</el-radio>
                  <el-radio style="margin-left: 10px" label="teacher">Teacher</el-radio>
                  <el-radio style="margin-left: 10px" label="parent">Onekids</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </el-col>
          </el-row>
        <el-row>
          <el-col :span="15">
            <el-form-item>
              <el-radio-group v-model="typePackage">
                <el-radio label="template">Giao diện</el-radio>
                <el-radio label="ticket" style="margin-left: 50px" v-if="createTemplateAppRequest.appType === 'plus'">Phiếu bé ngoan</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="typePackage === 'ticket'">
          <el-form-item label="Màn hình">
            <el-select v-model="displayTicket" placeholder="Chọn màn hình" @change="handleChangeDisplayTicket" style="width: 200px">
              <el-option
                  style="width: 200px"
                  v-for="item in listDisplayTicket"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-row>

        <!--        Bảng chọn ảnh -->

        <!--    APP PLUS    -->
        <el-table
            v-if="createTemplateAppRequest.appType === 'plus' && typePackage === 'template'"
            ref="multipleTable"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255,255,255, 0)"
            highlight-current-row
            :data="plusScreenList"
            :header-cell-style="tableHeaderColor"
            :max-height="500"
            border
        >
          <el-table-column
              type="index"
              label="STT"
              width="80"
              align="center"
          ></el-table-column>
          <el-table-column
              label="Màn hình"
              align="center"
          >
            <template v-slot:default="scope">
              <span>{{ scope.row.value }}</span>
            </template>
          </el-table-column>
          <el-table-column
              label="Chọn ảnh"
          >
            <template v-slot:default="scope">
              <el-upload
                  action="/api/upload/"
                  list-type="picture-card"
                  :auto-upload="false"
                  :file-list="getFileList(scope.row.file)"
                  :on-change="(file) => handleChangePicture(scope.row, file)"
                  :on-remove="(file) => handleRemove(scope.row, file)"
                  :before-remove="(file) => beforeRemove(scope.row, file)"
                  ref="upload"
                  accept="image/jpeg,image/png"
                  :disabled="scope.row.showFile === false"
                  limit="1"
              >
                <div v-if="scope.row.showFile === true">
                  <i slot="default" class="el-icon-plus" style="font-style: normal">Thêm ảnh</i>
                </div>
                <div slot="file" slot-scope="{ file }">
                  <span class="tag_image">New</span>
                  <img class="el-upload-list__item-thumbnail" :src="file.url" alt/>
                  <span class="el-upload-list__item-actions">
              <span
                  class="el-upload-list__item-preview"
                  @click="handlePictureCardPreview(scope.row, file)"
              >
                <i class="el-icon-zoom-in"></i>
              </span>
              <span
                  @click="handleRemove(scope.row, file)"
                  v-if="!scope.row.disabled"
                  class="el-upload-list__item-delete"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
                </div>
              </el-upload>
              <el-dialog width="30%" :visible.sync="scope.row.dialogImg">
                <img width="100%" :src="scope.row.url" alt/>
              </el-dialog>
            </template>
          </el-table-column>
        </el-table>

        <!--    APP TEACHER    -->
        <el-table
            v-if="createTemplateAppRequest.appType === 'teacher'  && typePackage === 'template'"
            ref="multipleTable"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255,255,255, 0)"
            highlight-current-row
            :data="teacherScreenList"
            :header-cell-style="tableHeaderColor"
            :max-height="500"
            border
        >
          <el-table-column
              type="index"
              label="STT"
              width="80"
              align="center"
          ></el-table-column>
          <el-table-column
              label="Màn hình"
              align="center"
          >
            <template v-slot:default="scope">
              <span>{{ scope.row.value }}</span>
            </template>
          </el-table-column>
          <el-table-column
              label="Chọn ảnh"
          >
            <template v-slot:default="scope">
              <el-upload
                  action="/api/upload/"
                  list-type="picture-card"
                  :auto-upload="false"
                  :file-list="getFileList(scope.row.file)"
                  :on-change="(file) => handleChangePicture(scope.row, file)"
                  :on-remove="(file) => handleRemove(scope.row, file)"
                  :before-remove="(file) => beforeRemove(scope.row, file)"
                  ref="upload"
                  accept="image/jpeg,image/png"
                  :disabled="scope.row.showFile === false"
                  limit="1"
              >
                <div v-if="scope.row.showFile === true">
                  <i slot="default" class="el-icon-plus" style="font-style: normal">Thêm ảnh</i>
                </div>
                <div slot="file" slot-scope="{ file }">
                  <span class="tag_image">New</span>
                  <img class="el-upload-list__item-thumbnail" :src="file.url" alt/>
                  <span class="el-upload-list__item-actions">
              <span
                  class="el-upload-list__item-preview"
                  @click="handlePictureCardPreview(scope.row, file)"
              >
                <i class="el-icon-zoom-in"></i>
              </span>
              <span
                  @click="handleRemove(scope.row, file)"
                  v-if="!scope.row.disabled"
                  class="el-upload-list__item-delete"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
                </div>
              </el-upload>
              <el-dialog width="30%" :visible.sync="scope.row.dialogImg">
                <img width="100%" :src="scope.row.url" alt/>
              </el-dialog>
            </template>
          </el-table-column>
        </el-table>

        <!--    APP PARENT    -->
        <el-table
            v-if="createTemplateAppRequest.appType === 'parent' && typePackage === 'template'"
            ref="multipleTable"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255,255,255, 0)"
            highlight-current-row
            :data="parentScreenList"
            :header-cell-style="tableHeaderColor"
            :max-height="500"
            border
        >
          <el-table-column
              type="index"
              label="STT"
              width="80"
              align="center"
          ></el-table-column>
          <el-table-column
              label="Màn hình"
              align="center"
          >
            <template v-slot:default="scope">
              <span>{{ scope.row.value }}</span>
            </template>
          </el-table-column>
          <el-table-column
              label="Chọn ảnh"
          >
            <template v-slot:default="scope">
              <el-upload
                  action="/api/upload/"
                  list-type="picture-card"
                  :auto-upload="false"
                  :file-list="getFileList(scope.row.file)"
                  :on-change="(file) => handleChangePicture(scope.row, file)"
                  :on-remove="(file) => handleRemove(scope.row, file)"
                  :before-remove="(file) => beforeRemove(scope.row, file)"
                  ref="upload"
                  accept="image/jpeg,image/png"
                  :disabled="scope.row.showFile === false"
                  limit="1"
              >
                <div v-if="scope.row.showFile === true">
                  <i slot="default" class="el-icon-plus" style="font-style: normal">Thêm ảnh</i>
                </div>
                <div slot="file" slot-scope="{ file }">
                  <span class="tag_image">New</span>
                  <img class="el-upload-list__item-thumbnail" :src="file.url" alt/>
                  <span class="el-upload-list__item-actions">
              <span
                  class="el-upload-list__item-preview"
                  @click="handlePictureCardPreview(scope.row, file)"
              >
                <i class="el-icon-zoom-in"></i>
              </span>
              <span
                  @click="handleRemove(scope.row, file)"
                  v-if="!scope.row.disabled"
                  class="el-upload-list__item-delete"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
                </div>
              </el-upload>
              <el-dialog width="30%" :visible.sync="scope.row.dialogImg">
                <img width="100%" :src="scope.row.url" alt/>
              </el-dialog>
            </template>
          </el-table-column>
        </el-table>

        <!--        Phiếu bé ngoan-->
        <div v-if="createTemplateAppRequest.appType === 'plus' && typePackage === 'ticket'" style="margin-left: 100px">
          <el-upload
              style="margin-left: 35px; clear: both"
              action="#"
              list-type="picture-card"
              :auto-upload="false"
              :file-list="fileListTicket"
              :on-change="handleChangePictureTicket"
              :on-remove="handleRemoveTicket"
              :before-remove="beforeRemove"
              :on-preview="handlePictureCardPreviewTicket"
              multiple
              ref="uploadTicket"
          >
            <i slot="default" class="el-icon-plus" style="font-style: normal; white-space: nowrap">Thêm Ảnh</i>
          </el-upload>
          <el-dialog width="50%" :visible.sync="dialogVisibleTicket">
            <img width="100%" :src="dialogImageUrlTicket" alt/>
          </el-dialog>
        </div>

        <div class="tab-infor-button">
          <el-button
              type="success"
              size="medium"
              :loading="loadingButton"
              mini
              @click="submitForm('createTemplateAppRequest')"
          >
            <i class="el-icon-circle-check"/>
            <span>Lưu</span>
          </el-button>
          <el-button
              type="danger"
              size="medium"
              @click="closeDialog()"
          >
            <i class="el-icon-circle-close"/>
            <span>Đóng</span>
          </el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>

</template>

<script>
import {mapActions, mapState} from "vuex";
import SystemConfigService from "@/services/AdminService/SystemConfigService";
import {listDisplayTicket, parentScreenList, plusScreenList, teacherScreenList} from "@/constant/listTemplate";
import templateConfigMixin from "@/global/mixinTemplate/templateConfig.mixin";

export default {
  components: {},
  props: {
    isOpenAddTemplateAppDialog: {
      type: Boolean,
      default: () => false,
    },
  },

  data() {
    return {
      agentList: "",
      checkedKeepPhoto: true,
      disabled: false,
      loadingData: true,
      loadingButton: false,
      dialogVisiblePicture: false,
      showUpload: false,

      createTemplateAppRequest: {
        nameTemplate: "",
        descriptionTemplate: "",
        appType: "plus",
        listMap: {},

      },
      plusScreenList,
      teacherScreenList,
      parentScreenList,
      listTicketLearn: [],
      listTicketEat: [],
      listTicketSleep: [],
      listTicketToilet: [],
      listTicketHealthy: [],
      listTicketComment: [],
      listTicketWeek: [],
      listTicketMonth: [],
      listTicketPeriodic: [],
      listTicket: [],
      typePackage: "template",
      displayTicket: 1,
      listDisplayTicket,
      fileListTicket: [],
      dialogVisibleTicket: false,
      dialogImageUrlTicket: '',
      rules: {
        nameTemplate: [
          {
            required: true,
            message: "Không được để trống",
            trigger: "blur",
          },
        ],
        descriptionTemplate: [
          {
            required: true,
            message: "Không được để trống",
            trigger: "blur",
          },
        ],
        appType: [
          {
            required: true,
            message: "Loại app không được để trống",
            trigger: "change",
          },
        ],
        idSchool: [
          {
            required: true,
            message: "Phải chọn trường",
            trigger: "change",
          },
        ],
      },
    };
  },
  watch: {
    "createTemplateAppRequest.appType"(newValue) {
      if (newValue !== 'plus') {
        this.typePackage = 'template';
      }
    }
  },
  mixins: [
    templateConfigMixin
  ],
  computed: {
    ...mapState('schoolStore', ['agentList']),
    ...mapState('schoolStore', ['schoolList']),
  },
  methods: {
    ...mapActions('schoolStore', ["fetchDataSchoolList"]),
    getFileList(file) {
      return file ? [file] : [];
    },
    tableHeaderColor() {
      return "background-color: #78a5e7;color: #fff;font-weight: bold;";
    },

    closeDialog() {
      this.clearAllData();
      this.$refs["createTemplateAppRequest"].resetFields();
      this.$emit("dialog-close");
    },
    closeDialogByButton(fromData) {
      this.clearAllData();
      this.$refs[fromData].resetFields();
      this.$emit("dialog-close");
    },

    clearAllData() {
      this.listTicketLearn= []
      this.listTicketEat= []
      this.listTicketSleep= []
      this.listTicketToilet= []
      this.listTicketHealthy= []
      this.listTicketComment= []
      this.listTicketWeek= []
      this.listTicketMonth= []
      this.listTicketPeriodic= []
      this.typePackage = "template"
      this.fileListTicket = []
      this.agentList = ""
      this.checkedKeepPhoto = true
      this.loadingData = true
      this.loadingButton = false
      this.dialogVisiblePicture = false
      this.showUpload = false
      this.createTemplateAppRequest =
          {
            idSchool: "",
            nameSchool: "",
            nameTemplate: "",
            descriptionTemplate: "",
            appType: "plus",
            listMap: {},
          }
      this.handleClearList()
    },

    handleClearList(){
      for (let i = 0; i < this.plusScreenList.length; i++) {
        this.plusScreenList[i].file = null;
        this.plusScreenList[i].url = '';
        this.plusScreenList[i].showFile = true;
        this.plusScreenList[i].id = null
        this.plusScreenList[i].showFile = true
        this.plusScreenList[i].disabled = false
        this.plusScreenList[i].urlKeep = ''
      }
      for (let i = 0; i < this.teacherScreenList.length; i++) {
        this.teacherScreenList[i].file = null;
        this.teacherScreenList[i].url = "";
        this.teacherScreenList[i].showFile = true;
        this.teacherScreenList[i].id = null
        this.teacherScreenList[i].showFile = true
        this.teacherScreenList[i].disabled = false
        this.teacherScreenList[i].urlKeep = ''
      }
      for (let i = 0; i < this.parentScreenList.length; i++) {
        this.parentScreenList[i].file = null;
        this.parentScreenList[i].url = '';
        this.parentScreenList[i].showFile = true;
        this.teacherScreenList[i].id = null
        this.teacherScreenList[i].showFile = true
        this.teacherScreenList[i].disabled = false
        this.teacherScreenList[i].urlKeep = ''
      }
    },

    submitForm(fromData) {
      this.$refs[fromData].validate((valid) => {
        if (valid) {
          const formData = new FormData();
          formData.append("nameTemplate", this.createTemplateAppRequest.nameTemplate);
          formData.append("descriptionTemplate", this.createTemplateAppRequest.descriptionTemplate);
          formData.append("appType", this.createTemplateAppRequest.appType);

          if (this.createTemplateAppRequest.appType === "plus") {
            for (let i = 0; i < this.plusScreenList.length; i++) {
              if (this.plusScreenList[i].file != null) {
                formData.append(`listMap[${this.plusScreenList[i].key}]`, this.plusScreenList[i].file.raw);
              }
            }
          } else if (this.createTemplateAppRequest.appType === "teacher") {
            for (let i = 0; i < this.teacherScreenList.length; i++) {
              if (this.teacherScreenList[i].file != null) {
                formData.append(`listMap[${this.teacherScreenList[i].key}]`, this.teacherScreenList[i].file.raw);
              }
            }
          } else {
            for (let i = 0; i < this.parentScreenList.length; i++) {
              if (this.parentScreenList[i].file != null) {
                formData.append(`listMap[${this.parentScreenList[i].key}]`, this.parentScreenList[i].file.raw);
              }
            }
          }
          if (this.createTemplateAppRequest.appType === "plus"){
            this.addImageTicketFormData(formData,this.listTicketLearn,this.listTicketEat,this.listTicketSleep,this.listTicketToilet,
                this.listTicketHealthy,this.listTicketComment,this.listTicketWeek,this.listTicketMonth,this.listTicketPeriodic)
          }
          this.loadingButton = true;
          SystemConfigService.createTemplateAppSystem(formData)
              .then((resp) => {
                this.$message({
                  message: resp.data.message,
                  type: "success",
                });
                setTimeout(() => {
                  this.closeDialog();
                }, 500);
              })
              .catch((err) => {
                this.$message({
                  message: err.response.data.message,
                  type: "error",
                });
              })
              .finally(() => {
                setTimeout(() => {
                  this.loadingButton = false;
                }, 500);
              });
        }
      });
    },

    handleRemove(row, file){
      console.log(file)
      if (this.createTemplateAppRequest.appType === "plus") {
        for (let i = 0; i < this.plusScreenList.length; i++) {
          if (this.plusScreenList[i].key === row.key) {
            this.plusScreenList[i].file = null;
            this.plusScreenList[i].url = '';
            this.plusScreenList[i].showFile = true;
          }
        }
      } else if (this.createTemplateAppRequest.appType === "teacher") {
        for (let i = 0; i < this.teacherScreenList.length; i++) {
          if (this.teacherScreenList[i].key === row.key) {
            this.teacherScreenList[i].file = null;
            this.teacherScreenList[i].url = "";
            this.teacherScreenList[i].showFile = true;
          }
        }
      } else {
        for (let i = 0; i < this.parentScreenList.length; i++) {
          if (this.parentScreenList[i].key === row.key) {
            this.parentScreenList[i].file = null;
            this.parentScreenList[i].url = '';
            this.parentScreenList[i].showFile = true;
          }
        }
      }
    },

    beforeRemove(file) {
      return this.$confirm(`Cancel the transfert of ${file.name} ?`);
    },
    handlePictureCardPreview(row, file) {
      if (this.createTemplateAppRequest.appType === "plus") {
        for (let i = 0; i < this.plusScreenList.length; i++) {
          if (this.plusScreenList[i].key === row.key) {
            this.plusScreenList[i].url = file.url;
            this.plusScreenList[i].dialogImg = true;
          }
        }
      } else if (this.createTemplateAppRequest.appType === "teacher") {
        for (let i = 0; i < this.teacherScreenList.length; i++) {
          if (this.teacherScreenList[i].key === row.key) {
            this.teacherScreenList[i].url = file.url;
            this.teacherScreenList[i].dialogImg = true;
          }
        }
      } else {
        for (let i = 0; i < this.parentScreenList.length; i++) {
          if (this.parentScreenList[i].key === row.key) {
            this.parentScreenList[i].url = file.url;
            this.parentScreenList[i].dialogImg = true;
          }
        }
      }
    },


    handleChangePicture(row, file) {
      if (this.createTemplateAppRequest.appType === "plus") {
        for (let i = 0; i < this.plusScreenList.length; i++) {
          if (this.plusScreenList[i].key === row.key) {
            this.plusScreenList[i].file = file;
            this.plusScreenList[i].url = file.url;
            this.plusScreenList[i].showFile = false;
          }
        }
      } else if (this.createTemplateAppRequest.appType === "teacher") {
        for (let i = 0; i < this.teacherScreenList.length; i++) {
          if (this.teacherScreenList[i].key === row.key) {
            this.teacherScreenList[i].file = file;
            this.teacherScreenList[i].url = file.url;
            this.teacherScreenList[i].showFile = false;
          }
        }
      } else {
        for (let i = 0; i < this.parentScreenList.length; i++) {
          if (this.parentScreenList[i].key === row.key) {
            this.parentScreenList[i].file = file;
            this.parentScreenList[i].url = file.url;
            this.parentScreenList[i].showFile = false;
          }
        }
      }
      this.checkedKeepPhoto = false
      const isJPG = file.raw.type === "image/jpeg";
      const isPNG = file.raw.type === "image/png";
      const isLt5M = file.size / 1024 / 1024 < 5;

      if (!isJPG && !isPNG) {
        this.$message.error("Ảnh phải có dạng JPG hoặc PNG!");
        this.handleRemove(file);
      }
      if (!isLt5M) {
        this.$message.error("Ảnh không thể vượt quá 5MB");
        this.handleRemove(file);
      }
      return isJPG && isLt5M;
    },

    handleChangeDisplayTicket(){
      this.fileListTicket = []
      switch (this.displayTicket){
        case 1: {
          this.fileListTicket = this.listTicketLearn
          break;
        }
        case 2: {
          this.fileListTicket = this.listTicketEat
          break;
        }
        case 3: {
          this.fileListTicket = this.listTicketSleep
          break;
        }
        case 4: {
          this.fileListTicket = this.listTicketToilet
          break;
        }
        case 5: {
          this.fileListTicket = this.listTicketHealthy
          break;
        }
        case 6: {
          this.fileListTicket = this.listTicketComment
          break;
        }
        case 7: {
          this.fileListTicket = this.listTicketWeek
          break;
        }
        case 8: {
          this.fileListTicket = this.listTicketMonth
          break;
        }
        case 9: {
          this.fileListTicket = this.listTicketPeriodic
          break;
        }
      }
    },
    // Chọn ảnh phiếu bé ngoan
    handleChangePictureTicket(file, fileList) {
      this.fileListTicket = fileList
      switch (this.displayTicket){
        case 1: {
          this.listTicketLearn = this.fileListTicket
          break;
        }
        case 2: {
          this.listTicketEat = this.fileListTicket
          break;
        }
        case 3: {
          this.listTicketSleep = this.fileListTicket
          break;
        }
        case 4: {
          this.listTicketToilet = this.fileListTicket
          break;
        }
        case 5: {
          this.listTicketHealthy = this.fileListTicket
          break;
        }
        case 6: {
          this.listTicketComment = this.fileListTicket
          break;
        }
        case 7: {
          this.listTicketWeek = this.fileListTicket
          break;
        }
        case 8: {
          this.listTicketMonth = this.fileListTicket
          break;
        }
        case 9: {
          this.listTicketPeriodic = this.fileListTicket
          break;
        }
      }
      const isJPG = file.raw.type === "image/jpeg";
      const isPNG = file.raw.type === "image/png";
      const isLt5M = file.size / 1024 / 1024 < 5;

      if (!isJPG && !isPNG) {
        this.$message.error("Ảnh phải có dạng JPG hoặc PNG!");
        this.handleRemoveTicket(file);
        return false; // Trả về false để không thực hiện tiếp tục xử lý
      }
      if (!isLt5M) {
        this.$message.error("Ảnh không thể vượt quá 5MB");
        this.handleRemoveTicket(file);
        return false; // Trả về false để không thực hiện tiếp tục xử lý
      }
      return true;
    },
    // Xem chi tiết ảnh phiếu bé ngoan
    handlePictureCardPreviewTicket(file) {
      this.dialogImageUrlTicket = file.url;
      this.dialogVisibleTicket = true;
    },
    // Xóa file phiếu bé ngoan
    handleRemoveTicket(file) {
      this.$refs.uploadTicket.clearFiles();
      let index;
      for (let i = 0; i < this.fileListTicket.length; i++) {
        if (file.uid === this.fileListTicket[i].uid){
          index = i
        }
      }
      if (index >= 0) {
        this.fileListTicket.splice(index, 1);
      }
      let indexTicket;
      switch (this.displayTicket) {
        case 1: {
          for (let i = 0; i < this.listTicketLearn.length; i++) {
            if (file.uid === this.listTicketLearn[i].uid){
              indexTicket = i
            }
          }
          if (indexTicket >= 0) {
            this.listTicketLearn.splice(indexTicket, 1);
          }
          break;
        }
        case 2: {
          for (let i = 0; i < this.listTicketEat.length; i++) {
            if (file.uid === this.listTicketEat[i].uid){
              indexTicket = i
            }
          }
          if (indexTicket >= 0) {
            this.listTicketEat.splice(indexTicket, 1);
          }
          break;
        }
        case 3: {
          for (let i = 0; i < this.listTicketSleep.length; i++) {
            if (file.uid === this.listTicketSleep[i].uid){
              indexTicket = i
            }
          }
          if (indexTicket >= 0) {
            this.listTicketSleep.splice(indexTicket, 1);
          }
          break;
        }
        case 4: {
          for (let i = 0; i < this.listTicketToilet.length; i++) {
            if (file.uid === this.listTicketToilet[i].uid){
              indexTicket = i
            }
          }
          if (indexTicket >= 0) {
            this.listTicketToilet.splice(indexTicket, 1);
          }
          break;
        }
        case 5: {
          for (let i = 0; i < this.listTicketHealthy.length; i++) {
            if (file.uid === this.listTicketHealthy[i].uid){
              indexTicket = i
            }
          }
          if (indexTicket >= 0) {
            this.listTicketHealthy.splice(indexTicket, 1);
          }
          break;
        }
        case 6: {
          for (let i = 0; i < this.listTicketComment.length; i++) {
            if (file.uid === this.listTicketComment[i].uid){
              indexTicket = i
            }
          }
          if (indexTicket >= 0) {
            this.listTicketComment.splice(indexTicket, 1);
          }
          break;
        }
        case 7: {
          for (let i = 0; i < this.listTicketWeek.length; i++) {
            if (file.uid === this.listTicketWeek[i].uid){
              indexTicket = i
            }
          }
          if (indexTicket >= 0) {
            this.listTicketWeek.splice(indexTicket, 1);
          }
          break;
        }
        case 8: {
          for (let i = 0; i < this.listTicketMonth.length; i++) {
            if (file.uid === this.listTicketMonth[i].uid){
              indexTicket = i
            }
          }
          if (indexTicket >= 0) {
            this.listTicketMonth.splice(indexTicket, 1);
          }
          break;
        }
        case 9: {
          for (let i = 0; i < this.listTicketPeriodic.length; i++) {
            if (file.uid === this.listTicketPeriodic[i].uid){
              indexTicket = i
            }
          }
          if (indexTicket >= 0) {
            this.listTicketPeriodic.splice(indexTicket, 1);
          }
          break;
        }
      }
    },

    handleTypeApp(){
      if (this.createTemplateAppRequest.appType !== 'plus'){
        this.typePackage = 'template'
      }
    },

  },
};
</script>
<style>
.hideUpload > div {
  visibility: hidden;
}
</style>
<style lang="scss" scoped>
// /deep/.el-input__inner,
// /deep/.el-textarea__inner {
//   border: none;
//   border-radius: 0;
//   resize: none;
//   padding-left: 0;
//   padding-right: 0;
//   border-bottom: 1px solid #d9d9d9;
// }

/deep/ .el-dialog__title {
  font-size: 25px;
  color: #606266;
}

/deep/ .el-form-item__label {
  font-family: Arial, Helvetica, sans-serif;
}

/deep/ .el-button--primary {
  width: 100%;
  background: #409eff;
}

/deep/ .upload-demo {
  text-align: center;

  .el-upload {
    width: 100%;
  }
}

/deep/ .el-avatar {
  display: inline;

  img {
    border-radius: 5px;
    width: 100%;
  }
}

/deep/ .el-input.is-disabled .el-input__inner {
  text-align: center;
  color: #d13b93;
  font-weight: bold;
}

/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.el-select {
  display: block;
}

.tab-infor-button {
  text-align: right;
  margin-top: 20px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.group-radio-status {
  margin: 0 50px;

  .kids-status {
    margin-bottom: 10px;
  }
}

/deep/ .el-tabs__nav {
  background: #a0a19ce0;
}

/deep/ .el-tabs__item {
  color: white;
}

/deep/ .el-tabs__item.is-active {
  color: white;
  background: #67c23a;
}

/deep/ .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:after {
  content: "*";
  color: #f56c6c;
  margin-left: 3px;
}

/deep/ .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  content: "";
  color: #f56c6c;
  margin-left: -4px;
}
/deep/ .el-upload--picture-card {
  background: none;
  border-radius: 6px;
  border: none ;/* Default border */
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 148px;
  height: 148px;
  cursor: pointer;
  line-height: 146px;
  vertical-align: top;
}
/deep/.el-table th.gutter {
  background-color: #78a5e7;
}

/deep/.el-upload-list__item.is-ready > div {
  height: 100%;
}

.tag_image {
  background: #409effe6;
  position: absolute;
  left: -20px;
  top: -4px;
  width: 60px;
  padding: 6px 0 0 0;
  text-align: center;
  color: #fff;
  rotate: -45deg;
  font-size: 12px;
  text-transform: capitalize;
}

/deep/.el-image__inner {
  object-fit: cover;
}
</style>
